import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';
import jQuery from 'jquery-slim';
import { writable, get } from 'svelte/store';

const isDevelopment = location.hostname === 'localhost';

export const screenshot = () => {
	html2canvas(document.body).then(function (canvas) {
		canvas.toBlob(function (blob) {
			FileSaver.saveAs(blob, 'typing.zidlle.me.png');
		});
	});
};

export let fastestSpeed = (() => {
	const { subscribe, set } = writable({
		short: 0,
		middle: 0,
		long: 0,
	});

	return {
		subscribe,
		set,
	};
})();

function depth() {
	const depth = location.pathname
		.replace(/\/?/, '')
		.split('/')
		.filter((r) => r !== '').length;
	return depth;
}

window.isTouch = (function is_touch_device4() {
	var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

	var mq = function (query) {
		return window.matchMedia(query).matches;
	};

	if (
		'ontouchstart' in window ||
		(window.DocumentTouch && document instanceof DocumentTouch)
	) {
		return true;
	}

	// include the 'heartz' as a way to have a non matching MQ to help terminate the join
	// https://git.io/vznFH
	var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
		''
	);
	const isTouch = mq(query);
	document
		.getElementsByTagName('html')[0]
		.classList.add(isTouch ? 'touch' : 'no-touch');

	return isTouch;
})();

// export const language = (() => {
// 	let baseLanguage =
// 		localStorage.language ||
// 		(navigator.language || navigator.userLanguage)
// 			.substring(0, 2)
// 			.toLowerCase();
// 	if (['en', 'ko'].indexOf(baseLanguage) === -1) {
// 		baseLanguage = 'ko';
// 	}

// 	const { subscribe, set } = writable(baseLanguage);

// 	const change = (newLangCode) => {
// 		set(newLangCode);
// 		localStorage.language = newLangCode;
// 	};

// 	return {
// 		subscribe,
// 		change,
// 	};
// })();

let nowInterval;
export const now = (() => {
	const { subscribe, set } = writable(+new Date());

	const init = () => {
		nowInterval = setInterval(() => {
			set(+new Date());
		}, 1000 * 60);
	};

	const destroy = () => {
		clearInterval(nowInterval);
	};

	return {
		subscribe,
		init,
		destroy,
	};
})();

function checkAlertCallbackAndFlush() {
	let executed = false;
	if (typeof window.__ALERT_CALLBACK === 'function') {
		window.__ALERT_CALLBACK();
		window.__ALERT_CALLBACK = undefined;
		executed = true;
	}
	return executed;
}

(() => {
	window.onresize = () => {
		if (!window.isTouch) {
			const isFullscreen = window.innerHeight === window.outerHeight;
			document
				.querySelector('html')
				.classList[isFullscreen ? 'add' : 'remove']('fullscreen');
		}
	};

	window.onerror = () => {
		if (confirm('An unknown error has occurred. Do you want to refresh?')) {
			location.reload();
		}
	};

	jQuery(document).on('click', '.btn-closed', function () {
		window.routes.pop();
		return false;
	});

	jQuery(document).on('click', function () {
		jQuery('*:enabled:visible:first').focus();
		checkAlertCallbackAndFlush();
	});

	window.onkeydown = (e) => {
		const ENTER = 13;
		const SPACE = 32;
		const ESC = 27;
		const UP = 38;
		const DOWN = 40;
		const S = 83;

		const { keyCode } = e;

		if (keyCode === ESC || keyCode === ENTER) {
			if (checkAlertCallbackAndFlush()) {
				return false;
			}
		}

		if ((e.metaKey || e.ctrlKey) && keyCode === S) {
			e.preventDefault();
			e.stopPropagation();

			screenshot();

			return false;
		}

		// https://firejune.com/731/event.keyCode+%EB%B2%88%ED%98%B8%ED%91%9C
		switch (keyCode) {
			case ENTER:
			case SPACE:
			case UP:
			case DOWN:
				const _menu = jQuery(`.frame:last .menu`);
				if (_menu.length > 0) {
					const _active = _menu.find('.btn.active');
					if (
						(keyCode === ENTER || keyCode === SPACE) &&
						_active.length > 0
					) {
						_active.get(0).click(); 
					} else {
						const maxIndex = _menu.find('.btn').length - 1;
						let targetIndex = _active.index() + (e.keyCode - 39);
						if (_active.index() > -1) {
							_active.removeClass('active');
						}
						if (targetIndex < 0) {
							targetIndex = maxIndex;
						} else if (targetIndex > maxIndex) {
							targetIndex = 0;
						}
						_menu.find('.btn').eq(targetIndex).addClass('active');
					}
				}
				break;
		}
	};
})();
