<script>
	import _ from 'lodash';
	import { settings } from './settings.js';
	import Typing from './Typing.svelte';
	import data from './middle.js';
	import Alert from './Alert.svelte';

	const MAX_SENTENCE = 5;

	let alertTitle = '';
	let alertMsg = '';
	let alertCallback;

	export let params;
	const dataId = params[0];
	let startIndex = $settings.middle[dataId] || 0;
	let prevSpeed = 0;
	const row = data.find((row, index) => row._id == dataId);
	let sentence = row.content;

	function goNextSentence(correctRate, speed) {
		if (correctRate === undefined) {
			// 더 이상 내용이 없어서 종료된 경우
			console.log(`더 이상 내용이 없어서 종료된 경우`);
			settings.setIn(['middle', dataId], 0);
			window.routes.pop();
			return false;
		}

		// alert을 띄운다
		alertMsg = `정확도 : ${(correctRate * 100).toFixed(0)}%<br />타속 : ${speed} 타`;
		alertCallback = () => {
			alertMsg = '';
			sentence = '';
			prevSpeed = speed;
			setTimeout(() => {
				// 다음 페이지
				startIndex += MAX_SENTENCE;
				settings.setIn(['middle', dataId], startIndex);
				sentence = row.content;
			}, 10);
		};
	}
</script>

<div class="frame-inner full">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
		<div>
			{row.title}
		</div>
		<div></div>
	</div>
	<div class="frame-content">
		{#if sentence !== ''}
			<Typing
				type="middle"
				data={sentence}
				{startIndex}
				maxSentence={MAX_SENTENCE}
				onFinish={goNextSentence}
				{prevSpeed}
			/>
		{/if}
	</div>

	<Alert title={alertTitle} msg={alertMsg} callback={alertCallback} />
</div>

<style lang="scss">
</style>
