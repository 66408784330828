<script>
	import Trans from './Trans.svelte';

	export let handleDownload;
	export let handleTry;
</script>

<style>
	#wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background: #011375;
	}
	h1 {
		color: #fff;
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 2rem;
	}
	img {
		display: inline-block;
		border-radius: 100%;
		width: 120px;
	}
	p {
		text-align: center;
		max-width: 500px;
		padding: 0 20px;
		margin-bottom: 2rem;
	}

	button {
		border: 2px solid #fff;
		color: #fff;
		margin: 0.2rem;
		background: transparent;
		font-size: 1rem;
    	padding: 0.2rem 0.4rem;
		border-radius: 2px;
	}
</style>

<div id="wrapper">
	<h1>
		<Trans key="title" />
	</h1>
	<p>
		<Trans
			ko="레트로 감성의 온라인 타이핑 프로그램으로 단문, 장문연습 및 타자게임을 제공합니다. 아래에서 원하는 방식을 선택하세요."
			en="레트로 감성의 온라인 타이핑 프로그램. 단문, 장문연습 및 타자게임 제공"
		/>
	</p>

	<div>
		<button on:click="{handleDownload}">
			<Trans ko="다운로드" en="Download" />
		</button>
		<button on:click="{handleTry}">
			<Trans ko="바로 시작" en="Try it online" />
		</button>
	</div>
</div>
