<script>
	import { onMount } from 'svelte';
	import jQuery from 'jquery-slim';

	onMount(() => {
		setTimeout(() => {
			jQuery('*:enabled:visible:first').focus();
		}, 100);
	});
</script>

<style lang="scss">
	.frame-content {
		padding: 2rem;
		textarea {
			font-size: 1rem;
			line-height: 1.3rem;
			width: 100%;
			height: 100%;
			background: transparent;
			border: 0;
			box-shadow: none;
			outline: none;
			overflow: hidden;
			padding: 0;
			text-align: left;
			color: #f4f4f4;
			color: var(--txt-color);
			resize: none;
		}
	}
</style>

<div id="note" class="frame-inner full">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
	</div>
	<div class="frame-content">
		<textarea data-focus="true" cols="30" rows="10"></textarea>
	</div>
</div>
