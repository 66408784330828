<script>
	import { settings } from './settings.js';
	import langs from './langs.js';
	import colors from './colors.js';

	export let params;
	$: {
		if (params[0] !== undefined) {
			settings.setIn('color', params[0]);
			window.routes.pop();
		}
	}
</script>

<div id="color" class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed">
			<span>&times;</span>
		</div>
	</div>
	<div class="frame-content">
		{#each colors as colorUnit, index}
			<a
				on:click={window.routes.last.bind(
					null,
					`color.${colorUnit._id}`
				)}
				class="btn {$settings.color === colorUnit._id ? 'active' : ''}"
			>
				{colorUnit._id}
			</a>
		{/each}
	</div>
</div>

<style lang="scss">
	#color.frame-inner {
		width: 300px;
	}
</style>
