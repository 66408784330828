<script>
	import { settings } from './settings.js';
	import langs from './langs.js';
	import data from './short.js';
</script>

<div class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
	</div>
	<div class="frame-content">
		{#each data[$settings.language] as row, index}
			<a
				on:click={window.routes.push.bind(null, `short.${index}`)}
				class="btn {index === 0 ? 'active' : ''}">{row.title}</a
			>
		{/each}
	</div>
</div>
