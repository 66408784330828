<script>
	import VenicePlay from './VenicePlay.svelte';

	let total = 0;
	export let params;
	let level = Number(params[0]);
	console.log('level', level);
</script>

<style lang="scss">
</style>

<div class="frame-inner full">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
	</div>
	<div class="frame-content">
		<VenicePlay level={level} total={total} />
	</div>
</div>
