<script>
	import { onMount } from 'svelte';
	import { settings } from './settings.js';
	import langs from './langs.js';
	import sound from './sound.js';
	import Hangul from 'hangul-js';
	import words from './words.js';
	import jQuery from 'jquery-slim';
	import _ from 'lodash';
	import Alert from './Alert.svelte';
	import levelData from './level-data.js';

	export let level = 1;

	const WIDTH_COUNT = 4;
	const HEIGHT_COUNT = 4;
	const MAX_TIME = 100; // 제한시간 초
	const PROGRESS_INTERVAL = 1000;
	const COMPUTER_INTERVAL = levelData[level].delay;
	const KEY_SPACE = 32;
	const KEY_ENTER = 13;

	let bottomHeight = 10;
	let width = 10;
	let stepDistance = 10;

	let computerText = [];
	let computerTyped = [];
	let humanTyped = '';

	let alertTitle = '';
	let alertMsg = '';
	let alertCallback;
	let elapsedSeconds = MAX_TIME;
	let displays = [];

	$: {
		if (/\s/.test(humanTyped)) {
			handleHumanTyping();
		}
	}

	function handleHumanTyping() {
		hit('human', humanTyped.split(/\s/)[0]);
		humanTyped = '';
	}

	function handleSubmit() {
		handleHumanTyping();
	}

	function endGame(status = '') {
		// 에너지가 더 작은 사람이 진 거임
		clearInterval(progressInterval);
		clearInterval(computerInterval);

		if (status === '') {
			const scores = checkScores();
			status = scores.human > scores.computer ? 'win' : 'lose';
		}

		if (status === 'lose') {
			alertTitle = '패배!';
			alertMsg = levelData[level].lose;
			alertCallback = () => {
				window.routes.pop();
			};
		} else {
			alertTitle = '승리!';
			alertMsg = levelData[level].win;
			alertCallback = () => {
				window.routes.pop();
				if (level > 7) {
				} else {
					setTimeout(() => {
						window.routes.last('area.' + (level + 1));
					}, 100);
				}
			};
		}
	}

	function checkScores() {
		const scores = {
			human: 0,
			computer: 0,
		};
		let computerWords = 0;
		displays.forEach((row) => {
			row.forEach((word) => {
				if (word.side !== '') {
					scores[word.side]++;
				}
			});
		});
		return scores;
	}

	// 타이핑을 완료한다
	function hit(mySide, text) {
		text = text.trim();
		console.log('text', text);
		displays = displays.map((rows) =>
			rows.map((word) => {
				if (word.text === text) {
					if (mySide === 'human') {
						sound('next');
					}
					if (word.side === '') {
						word.side = mySide; // 내가 차지한다
					} else {
						word.side = ''; // 중립으로 돌려놓는다
					}
					word.text = pickTextWithoutDuplicate(); // 새로운 단어를 부여한다
				}
				return word;
			})
		);
		setTimeout(() => {
			const scores = checkScores();
			const maxCount = WIDTH_COUNT * HEIGHT_COUNT;
			if (scores.human === maxCount) {
				endGame('win');
			} else if (scores.computer === maxCount) {
				endGame('lose');
			}
		}, 30);
	}

	function pickComputerText() {
		const targets = [];
		displays.forEach((row) => {
			row.forEach((word) => {
				if (word.side !== 'computer') {
					targets.push(word.text);
				}
			});
		});

		let text;
		if (targets.length > 0) {
			text = _.sample(targets);
		}
		if (text !== undefined) {
			computerText = Hangul.disassemble(text);
		}
	}

	function hitByComputer() {
		if (computerText.length === 0) {
			hit('computer', Hangul.assemble(computerTyped));
			computerTyped = [];
		} else {
			computerTyped = [...computerTyped, computerText.shift()];
		}
	}

	function pickTextWithoutDuplicate() {
		const pick = _.sample(words[$settings.language]);
		let isExist = false;
		displays.forEach((row) => {
			row.forEach((word) => {
				if (word.text === pick) {
					isExist = true;
				}
			});
		});
		if (isExist) {
			return pickTextWithoutDuplicate();
		}
		return pick;
	}

	let progressInterval;
	let computerInterval;
	onMount(() => {
		// 최초 낱말 배치
		for (var i = 0; i < HEIGHT_COUNT; i++) {
			displays[i] = [];
			for (var j = 0; j < WIDTH_COUNT; j++) {
				displays[i].push({
					text: pickTextWithoutDuplicate(),
					side: '',
				});
			}
		}

		// 진행을 위한 인터벌
		progressInterval = setInterval(() => {
			elapsedSeconds -= PROGRESS_INTERVAL / 1000;
			if (elapsedSeconds < 0) {
				elapsedSeconds = 0;
				endGame();
			}
		}, PROGRESS_INTERVAL);

		// 컴퓨터 타이핑
		computerInterval = setInterval(() => {
			if (computerTyped.length > 0 || computerText.length > 0) {
				hitByComputer();
			} else {
				pickComputerText();
			}
		}, COMPUTER_INTERVAL);

		// human에 포커스
		jQuery('*[data-fucus="true"]').focus();

		return () => {
			clearInterval(progressInterval);
			clearInterval(computerInterval);
		};
	});
</script>

<div id="wrapper">
	<div id="monitor">
		<form on:submit|preventDefault={handleSubmit} class="player human">
			<input
				id="human"
				name="human"
				data-fucus="true"
				type="text"
				placeholder={langs.human[$settings.language]}
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				spellCheck="false"
				bind:value={humanTyped}
			/>
		</form>

		<div class="time">Lv.{level} / {parseInt(elapsedSeconds, 10)}s</div>

		<div id="computer-player" class="player computer">
			<input
				name="computer"
				type="text"
				placeholder={langs.computer[$settings.language]}
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				spellCheck="false"
				readonly
				value={Hangul.assemble(computerTyped)}
			/>
		</div>
	</div>

	<div id="grid">
		{#each displays as row, index (index)}
			<div class="row">
				{#each row as word (word.text)}
					<div class="word {word.side}">
						<span>{word.text}</span>
					</div>
				{/each}
			</div>
		{/each}
	</div>

	<Alert title={alertTitle} msg={alertMsg} callback={alertCallback} />
</div>

<style lang="scss">
	#wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	#monitor {
		display: flex;
		justify-content: space-between;

		.time {
			padding: 0.15rem 0;
		}

		.player {
			display: flex;
			input {
				background: #000;
				border: 0;
				padding: 0.15rem;
			}

			&.human {
				.color {
					background: #eee;
				}
				input {
					color: #eee;
				}
			}

			&.computer {
				.color {
					background: #ff3c01;
				}

				input {
					color: #ff3c01;
				}
			}

			.color {
				display: inline-block;
				width: 20px;
			}

			input {
				font-size: 1rem;
				box-shadow: none;
				outline: none;
				padding-left: 0.2rem;
			}
		}
	}

	#grid {
		display: flex;
		flex: 1;
		width: calc(100% + 2px);
		margin-left: -1px;
		flex-direction: column;
		border-top: 1px solid #f4f4f4;
		border-top-color: var(--txt-color);

		.row {
			display: flex;
			flex: 1;
			justify-content: stretch;

			.word {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #f4f4f4;
				border-color: var(--txt-color);
				> span {
					display: inline-block;
					color: #f4f4f4;
					color: var(--txt-color);
					padding: 0 0.15rem;
				}

				&.human {
					background: #878787;
					> span {
						color: #011375;
						color: var(--txt-color);
					}
				}

				&.computer {
					background: #ff0000;
					> span {
						color: #011375;
						color: var(--bg-color);
					}
				}
			}
		}
	}
</style>
