<script>
	import jQuery from 'jquery-slim';
	import { onMount } from 'svelte';
	import Trans from './Trans.svelte';
	import sound from './sound.js';
	import { fastestSpeed } from './store.js';
	import Hangul from 'hangul-js';

	export let maxSentence = 5;
	export let prevSpeed = 0;
	export let data = '';
	export let typed = '';
	export let type = '';
	export let onFinish = undefined;
	export let startIndex = 0;
	let lineIndex = 0;
	let correctRate = 0;
	let speed = 0;
	let wrongs = 0;
	let lastValidStartAt = -1;

	let winWidth = window.innerWidth > 850 ? 850 : window.innerWidth;
	$: maxBytes = Math.floor(winWidth / 13.4);
	$: thisStartIndex = startIndex / (maxBytes / 63); // 폭이 변경되는 경우 현재 페이지를 최대한 유지하기 위함
	$: lines = splitContentToLines(data, thisStartIndex, maxSentence, maxBytes);

	function splitContentToLines(sentence, thisStartIndex, maxSentence) {
		function obj(str) {
			str = str.trim();
			return {
				example: str,
				exampleArr: str.split(''),
				exampleByteLength: byteCheck(str),
				typed: '',
				typedArrLength: 0,
				typedDisassembleLength: 0,
				wrongs: str.split('').map((a) => false),
			};
		}
		const lines = [];
		sentence
			.trim()
			.split(/\n/g)
			.forEach((line) => {
				let combined = '';
				line.trim()
					.split(/\s/g)
					.forEach((word) => {
						const n = `${combined} ${word}`;
						if (byteCheck(n) > maxBytes) {
							lines.push(obj(combined));
							combined = word; // 해당 단어로 시작하는 새로운 문장
						} else {
							combined = n;
						}
					});
				if (combined.trim() !== '') {
					lines.push(obj(combined));
				}
			});
		// console.log('lines', lines);
		return lines.slice(thisStartIndex, thisStartIndex + maxSentence);
	}

	// 출처: https://cofs.tistory.com/267 [CofS]
	function byteCheck(str) {
		var codeByte = 0;
		for (var idx = 0; idx < str.length; idx++) {
			var oneChar = escape(str.charAt(idx));
			if (oneChar.length == 1) {
				codeByte++;
			} else if (oneChar.indexOf('%u') != -1) {
				codeByte += 2;
			} else if (oneChar.indexOf('%') != -1) {
				codeByte++;
			}
		}
		return codeByte;
	}

	function setSelectionRange(input, selectionStart, selectionEnd) {
		if (input.setSelectionRange) {
			input.focus();
			input.setSelectionRange(selectionStart, selectionEnd);
		} else if (input.createTextRange) {
			var range = input.createTextRange();
			range.collapse(true);
			range.moveEnd('character', selectionEnd);
			range.moveStart('character', selectionStart);
			range.select();
		}
	}

	function getSpeed() {
		const currentAt = +new Date();
		const countTotalTypedDisassdmble = lines.reduce(
			(total, l) => total + l.typedDisassembleLength,
			0
		);
		if (countTotalTypedDisassdmble === 1) {
			lastValidStartAt = currentAt - 120; // 한 글자를 치는 시점에서 카운트 시작
		}
		const spentMinutes = (currentAt - lastValidStartAt) / 1000 / 60;
		let speed = 0;
		if (spentMinutes > 0 && countTotalTypedDisassdmble > 5) {
			speed = (countTotalTypedDisassdmble / spentMinutes) * correctRate; // 오타만큼 깐다
		}
		return Math.floor(speed);
	}

	function handleKeyup(e) {
		const SPACE = 32;
		const ENTER = 13;
		if (e.keyCode === ENTER) {
			processTyping('ENTER');
		}
	}

	function getCorrectRate() {
		let correctRate = 1;
		const countWrongs = lines.reduce((total, l) => {
			console.log(`l`, l);
			return total + l.wrongs.filter((r) => !!r).length;
		}, 0);
		const countTotalTyped = lines.reduce(
			(total, l) => total + l.typedArrLength,
			0
		);
		if (countTotalTyped > 0 && countWrongs > 0) {
			correctRate = 1 - countWrongs / countTotalTyped;
		}
		return correctRate;
	}

	function isAscii(char) {
		// 문자의 유니코드 값을 가져옴
		const code = char.charCodeAt(0);
		// 유니코드 값이 0부터 127 사이인지 확인
		return code >= 0 && code <= 127;
	}

	function processTyping(from = '') {
		// console.log('processTyping');
		if (lines.length === 0) {
			onFinish();
			return false;
		}
		const line = lines[lineIndex];
		line.typed = typed;

		// 현재 라인에서 오타인덱스 검사하기
		const typedArr = line.typed.split('');
		const lastChar = typedArr.pop(); // 타이핑중인 글자는 제거
		if (lastChar !== undefined && isAscii(lastChar)) {
			typedArr.push(lastChar);
		}

		line.typedArrLength = typedArr.length;
		const prevWrongs = line.wrongs.filter((r) => r === true);
		line.exampleArr.forEach((c, index) => {
			line.wrongs[index] = false;
			if (typedArr[index] !== undefined && c !== typedArr[index]) {
				line.wrongs[index] = true; // 오답
			}
		});
		const newWrongs = line.wrongs.filter((r) => r === true);
		if (prevWrongs < newWrongs) {
			sound('wrong');
		}
		line.typedDisassembleLength = Hangul.disassemble(typed).length;
		lines[lineIndex] = line;

		// 정확도 계산
		correctRate = getCorrectRate();

		// 속도 계산
		speed = getSpeed();

		// 한 문장을 완료한 경우
		const typedByteLength = byteCheck(line.typed);
		if (
			((typedByteLength === line.exampleByteLength && from === 'ENTER') ||
				typedByteLength > line.exampleByteLength) &&
			correctRate === 1
		) {
			line.typedArrLength = line.typed.split('').length;

			lineIndex++;
			if (typeof lines[lineIndex] !== 'undefined') {
				// 다음줄로 이동하거나
				typed = ''; // 타이핑 리셋
				setTimeout(() => {
					jQuery(`input[data-index="${lineIndex}"]`).focus();
				}, 1);
			} else {
				// 타이핑을 완료하기
				if (speed > $fastestSpeed[type]) {
					$fastestSpeed[type] = speed;
					sound('success');
				} else {
					sound('next');
				}

				onFinish(correctRate, speed);
			}
		}
	}

	$: {
		processTyping(typed);
	}

	onMount(() => {
		lastValidStartAt = +new Date();

		setTimeout(() => {
			typed = '';
			jQuery(`input[data-index="${lineIndex}"]`).focus();
		}, 50);
	});
</script>

<svelte:window
	on:resize={() => {
		winWidth = window.innerWidth > 850 ? 850 : window.innerWidth;
	}}
/>

<div class="wrapper">
	<div class="top">
		{#each lines as line, index (index)}
			<div>
				<div class="example">
					{#each line.exampleArr as char, i (i)}
						<div
							data-i={i}
							data-d={line.wrongs[i]}
							class={!!line.wrongs[i] ? 'wrong' : ''}
						>
							{char}
						</div>
					{/each}
				</div>
				{#if lineIndex !== index}
					<input
						typd="text"
						readonly
						disabled
						data-index={index}
						class="sentence"
						value={line.typed}
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="off"
						spellCheck="false"
					/>
				{:else}
					<input
						typd="text"
						data-index={index}
						data-focus="true"
						class="sentence"
						bind:value={typed}
						on:keyup={handleKeyup}
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="off"
						spellCheck="false"
					/>
				{/if}
			</div>
		{/each}
	</div>
	<div class="bottom-info">
		<div>
			<Trans key="prevspeed" append=" : " />{prevSpeed}
			<Trans key="ta" />
		</div>
		<div>
			<Trans key="currentspeed" append=" : " />{speed}
			<Trans key="ta" />
		</div>
		<div>
			<Trans key="precisie" append=" : " />{(correctRate * 100).toFixed(
				0
			)}%
		</div>
	</div>
</div>

<style lang="scss">
	.wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		.top {
			position: relative;
			flex: 1;
			min-height: 6rem;
			padding: 1rem 0 1rem 1.5rem;
			.example {
				height: 1.9rem;
				> div {
					height: 1.9rem;
					display: inline-block;
					min-width: 0.5rem;
					overflow: hidden;
					position: relative;
					padding-top: 0.7rem;
					color: #f4f4f4;
					color: var(--txt-color);
					&.wrong::after {
						content: '';
						position: absolute;
						background: url(/wrong3.png) no-repeat;
						background-size: contain;
						top: 0.4rem;
						left: 50%;
						width: 10px;
						height: 6px;
						margin-left: -5px;
					}
				}
			}
			.sentence {
				font-size: 1rem;
				width: 100%;
				margin: auto;
				background: transparent;
				border: 0;
				box-shadow: none;
				outline: none;
				overflow: hidden;
				padding: 0;
				text-align: left;
				opacity: 0.7;
			}
		}
	}

	@media (max-width: 680px) {
		.wrapper {
			.top {
				padding-left: 0.75rem;
			}
			:global(.bottom-info .trans) {
				display: none;
			}
		}
	}
</style>
