const levelData = {
	0: {
		delay: 700,
		win: '',
		lose: '',
	},
	1: {
		delay: 534,
		win: '게임할 실력은 되는군',
		lose: '하하! 너무 초보시군',
	},
	2: {
		delay: 380,
		win: '어느정도는 치는군',
		lose: '연습을 조금 더 하고와야겠군',
	},
	3: {
		delay: 256,
		win: '슬슬 달려볼까~',
		lose: '너무 실망하지는 마~',
	},
	4: {
		delay: 197,
		win: '긴장하라구, 이젠 봐주지 않는다',
		lose: '하하, 아직 나한테는 안돼',
	},
	5: {
		delay: 151,
		win: '이제 정말로 봐주지 않을거다',
		lose: '좋은 승부였다',
	},
	6: {
		delay: 117,
		win: '이제부터 진짜 내 실력을 보여주지',
		lose: '그래도 꽤 빠른편이니까 힘내',
	},
	7: {
		delay: 90,
		win: '이제 진짜 초인의 힘을 발휘해주지',
		lose: '너가 못하는게 아니야 내가 뛰어난거지',
	},
	8: {
		delay: 70,
		win: 'gg',
		lose: '수고했다 친구',
	},
	9: {
		delay: 50,
		win: 'gg',
		lose: '',
	},
	10: {
		delay: 30,
		win: 'gg',
		lose: '',
	},
};

export default levelData;
