import { settings } from './settings.js';
import { get } from 'svelte/store';

const mp3 = {
	wrong: new Audio('/wrong.mp3'),
	success: new Audio('/success.mp3'),
	next: new Audio('/next.mp3'),
};

const sound = (type) => {
	if (get(settings).sound === 'n') {
		return false;
	}
	const _ = mp3[type];
	_.currentTime = 0;
	_.play();
};

export default sound;
