import { writable, get } from 'svelte/store';

const hashChangeTimeGap = 5;

const routes = (function () {
	const opts = {
		delimiter: '.',
		callback: null,
		escToBack: true,
		hashType: 'hash', // hash or push
	};

	const _this = writable([]);
	const { subscribe, set } = _this;
	const tmp = {
		oldSegments: []
	}

	const init = (_opts) => {
		Object.keys(_opts).forEach((k) => {
			opts[k] = _opts[k];
		});

		if (opts.escToBack) {
			window.onkeyup = e => {
				if (e.key === 'Escape') {
					pop();
				}
			}
		}

		setRoutesOnHashChanged();
	}

	const _parsingSegments = () => {
		return location.hash.replace('#!', '').split('/').filter(seg => seg !== '');
	}

	// 초기 시작 혹은 hash 변경시 기존의 값과 비교해서 routes 상태 업데이트
	const setRoutesOnHashChanged = () => {
		const segments = _parsingSegments();
		console.log(`segments`, segments);
		const newRoutes = segments.map(segment => {
			const tmp = segment.split(opts.delimiter);
			const component = tmp.shift();
			const params = tmp.slice();
			return {
				component,
				segment,
				params,
			};
		});
		set(newRoutes);

		if (typeof callback === 'function') {
			callback(newRoutes);
		}
	}

	// 하위 경로로 진입
	const push = (segment) => {
		console.log(`push`, segment);
		const segments = get(_this);
		// browser hash change 일으켜서 재지정 일으킴
		location.hash = (segments.length > 0 ? location.hash + '/' : '!/') + segment;
	}

	// 상위 경로로 이동
	const pop = () => {
		const segments = get(_this);
		if (segments.length > 0) {
			// browser hash change 일으켜서 재지정 일으킴
			history.back();
		}
	}

	// 마지막 계층의 값을 변경
	const last = (segment) => {
		const segments = get(_this);
		segments.pop(); // 마지막은 제거
		let hash = segments.map(seg => seg.segment).join('/');
		const newHash = hash + (hash !== '' ? '/' : '') + segment;
		console.log(`newHash`, newHash);

		// history.back시 뒤로가야하므로, history없이 변경
		history.replaceState(null, '', location.pathname + '#!/' + newHash);
		setRoutesOnHashChanged();
	}

	// 현재 계층과 무관하게 특정한 곳으로 바로 이동하는 경우(하지만 호환성을 위해서 아무렇게나 써도 상황에 따라 push/pop/last를 일으킴)
	// 자연스럽게 계층간 이동이 아니라서, 이동 후 뒤로가기에 대해서는 상위가 아니라 
	const go = (segment, isHierarchy = false) => {
		// 가장 먼저 현재 레벨을 단순 치환하는 것인지 확인
		const segments = get(_this);
		const lastDepth = segments.length - 1;
		const targetComponent = segment.split(opts.delimiter)[0];
		let doneFunc;
		if (segments.length > 0) {
			segments.some((segment, depth) => {
				if (segment.component === targetComponent) {
					// 현재의 라우트중 어딘가에 존재한다면
					if (depth === lastDepth) {
						// 마지막 이라면 단순 교체하고 종료
						doneFunc = () => {
							last(segment);
						}
					} else {
						// 상위 어딘가에 존재하는 놈이라면,
						// 해당 라우팅으로 조정 후 같은 레벨을 교체
						doneFunc = () => {
							depth(depth, () => {
								last(segment);
							});
						}
					}
					return true;
				}
			});
			if (typeof doneFunc === 'function') {
				return doneFunc();
			}
		}

		// 세그먼트가 상대라면, push로 진입한다
		if (segment.indexOf('./') === 0) {
			segment = segment.replace('./', '');
		}
		if (segment.charAt(0) !== '/') {
			return push(segment);
		}

		// 전혀 다른 루트로 이동
		if (isHierarchy === false) {
			// 뒤로가기시 현재의 위치로 오도록 다이렉트로 이동
			history.replaceState(null, '', location.pathname + '#!' + segment);
			// hashchange 이벤트를 수동으로 트리거
			const hashChangeEvent = new Event('hashchange');
			window.dispatchEvent(hashChangeEvent);
		} else {
			// 뒤로가기시 해당 계층의 상위로 이동하도록 해시 조작
			location.hash = '';
			setTimeout(() => {
				segment.split('/').filter(seg => seg !== '').forEach((seg, i) => {
					setTimeout(() => {
						push(seg);
					}, i * hashChangeTimeGap);
				});
			}, hashChangeTimeGap)
		}
	}

	// 상위 특정 인덱스까지 이동
	const depth = (targetDepth, callback) => {
		const segments = get(_this);
		const depth = segments.length - 1;
		if (targetDepth === undefined) {
			// 현재의 depth 구하기
			return depth;
		}

		const gap = depth - targetDepth;
		// 상위로 가야하는 단계만큼 뒤로가기 일으킴
		for (let i = 0; i < gap; i++){
			setTimeout(pop, i * hashChangeTimeGap);
		}
		setTimeout(callback, ++i * hashChangeTimeGap);
	}

	return {
		subscribe,

		init,
		push,
		pop,
		last,
		depth,
		go,
		setRoutesOnHashChanged,
	};
})();

export default routes;

if (window.routes === undefined) {
	window.routes = routes;
	window.addEventListener('hashchange', () => {
		window.routes.setRoutesOnHashChanged();
	});
}
