<script>
	import Trans from './Trans.svelte';
	export let params;
	const type = params[0];
</script>

<div class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
	</div>
	<div class="frame-content">
		<a
			on:click={window.routes.push.bind(null, `${type}.1`)}
			class="btn active"><Trans key="level" /> 1</a
		>
		<a on:click={window.routes.push.bind(null, `${type}.2`)} class="btn"
			><Trans key="level" /> 2</a
		>
		<a on:click={window.routes.push.bind(null, `${type}.3`)} class="btn"
			><Trans key="level" /> 3</a
		>
		<a on:click={window.routes.push.bind(null, `${type}.4`)} class="btn"
			><Trans key="level" /> 4</a
		>
		<a on:click={window.routes.push.bind(null, `${type}.5`)} class="btn"
			><Trans key="level" /> 5</a
		>
		<a on:click={window.routes.push.bind(null, `${type}.6`)} class="btn"
			><Trans key="level" /> 6</a
		>
		<a on:click={window.routes.push.bind(null, `${type}.7`)} class="btn"
			><Trans key="level" /> 7</a
		>
	</div>
</div>
