const fonts = [
	'sansserif',
	'sansserif2',
	'serif1',
	'serif2',
	'saemmul',
	'd2coding',
];

export default fonts;
