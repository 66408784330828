<script>
	import { settings } from './settings.js';
	import Trans from './Trans.svelte';
	$: {
		console.log(`$settings`, $settings);
	}
</script>

<div id="main-menu" class="frame-inner menu">
	<div class="frame-header">
		<div></div>
		<Trans key="title" />
		<div></div>
	</div>
	<div class="frame-content">
		<div>
			<a
				on:click={window.routes.push.bind(null, 'shortlist')}
				class="btn active"><Trans ko="한 줄" en="Sentences" /></a
			>
			{#if $settings.language === 'ko'}
				<a
					on:click={window.routes.push.bind(
						null,
						`middlelist.${$settings.middlePageIndex}`
					)}
					class="btn"><Trans ko="짧은 글" en="Poem" /></a
				>
			{/if}
			<a
				on:click={window.routes.push.bind(
					null,
					`longlist.${$settings.longPageIndex}`
				)}
				class="btn"><Trans ko="긴 글" en="Stories" /></a
			>
			<a on:click={window.routes.push.bind(null, 'gamelist')} class="btn"
				><Trans ko="타자게임" en="Games" /></a
			>
			<a on:click={window.routes.push.bind(null, 'note')} class="btn"
				><Trans ko="연습장" en="Note" /></a
			>
			<a on:click={window.routes.push.bind(null, 'setting')} class="btn"
				><Trans ko="환경설정(Setting)" en="Setting" /></a
			>
			<a on:click={window.routes.push.bind(null, 'about')} class="btn"
				><Trans ko="풀그림 정보" en="About" /></a
			>
		</div>
	</div>
</div>

<style lang="scss">
	#main-menu {
		&::after {
			top: 17px;
			height: calc(100% + 4px);
		}
		.frame-header {
			text-align: center;
			font-size: 1.7rem;
			top: -80px;
			padding: 0.75rem 0;
			border: 0;
		}
	}
</style>
