const colors = [
	{
		_id: 'default',
		txt: '#f4f4f4',
		bg: '#011375',
	},
	{
		_id: 'hercules',
		txt: '#77fb12',
		bg: '#101010',
	},
	{
		_id: 'black',
		txt: '#e4e4e4',
		bg: '#101010',
	},
	{
		_id: 'white',
		txt: '#222',
		bg: '#f4f4f4',
	},
	{
		_id: 'man_page',
		txt: '#222',
		bg: '#fef3a1',
	},
	{
		_id: 'grass',
		txt: '#feefa9',
		bg: '#13582f',
	},
	{
		_id: 'homebrew',
		txt: '#fdcc3f',
		bg: '#222',
	},
	{
		_id: 'novel',
		txt: '#4c2f2e',
		bg: '#dfdac4',
	},
	{
		_id: 'red_sands',
		txt: '#d7c9a9',
		bg: '#782d24',
	},
	{
		_id: 'pink',
		txt: '#ffd2f8',
		bg: '#7b0c6c',
	},
];

export default colors;
