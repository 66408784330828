<script>
	import { settings } from './settings.js';
	import fonts from './fonts.js';
	import Trans from './Trans.svelte';

	export let params;
	$: {
		if (params[0] !== undefined) {
			settings.setIn('font', params[0]);
			window.routes.pop();
		}
	}
</script>

<div id="font" class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed">
			<span>&times;</span>
		</div>
	</div>
	<div class="frame-content">
		{#each fonts as font}
			<a
				on:click={window.routes.last.bind(null, `font.${font}`)}
				class="btn {$settings.font === font ? 'active' : ''}"
			>
				<Trans key={font} />
			</a>
		{/each}
	</div>
</div>

<style lang="scss">
	#font.frame-inner {
		width: 300px;
	}
</style>
