const words = {
	ko: [
		'하다',
		'있다',
		'있다',
		'되다',
		'하다',
		'없다',
		'않다',
		'사람',
		'우리',
		'보다',
		'보다',
		'같다',
		'주다',
		'가다',
		'때문',
		'오다',
		'알다',
		'크다',
		'사회',
		'많다',
		'좋다',
		'받다',
		'그것',
		'문제',
		'그런',
		'살다',
		'경우',
		'어떤',
		'그녀',
		'먹다',
		'오다',
		'자신',
		'문화',
		'생각',
		'소리',
		'다시',
		'다른',
		'이런',
		'여자',
		'정도',
		'듣다',
		'들다',
		'싶다',
		'함께',
		'아이',
		'많이',
		'시간',
		'주다',
		'인간',
		'사실',
		'나다',
		'시대',
		'다음',
		'누구',
		'여러',
		'하나',
		'세계',
		'운동',
		'학교',
		'자기',
		'가장',
		'가지',
		'들다',
		'바로',
		'어느',
		'무엇',
		'정부',
		'모든',
		'그거',
		'국가',
		'여기',
		'모두',
		'여성',
		'친구',
		'마음',
		'가다',
		'놓다',
		'관계',
		'남자',
		'어디',
		'얼굴',
		'말다',
		'지역',
		'모습',
		'내다',
		'쓰다',
		'이것',
		'없이',
		'이번',
		'생활',
		'쓰다',
		'지금',
		'사이',
		'방법',
		'내다',
		'앉다',
		'처음',
		'그때',
		'과정',
		'갖다',
		'찾다',
		'특히',
		'이상',
		'지금',
		'교육',
		'사다',
		'경제',
		'아직',
		'잡다',
		'같이',
		'예술',
		'서다',
		'역사',
		'읽다',
		'이제',
		'결과',
		'내용',
		'물론',
		'동안',
		'당신',
		'시장',
		'넣다',
		'무슨',
		'너무',
		'나라',
		'의미',
		'자리',
		'죽다',
		'이미',
		'정치',
		'국민',
		'생명',
		'얘기',
		'학생',
		'연구',
		'엄마',
		'이름',
		'하나',
		'사건',
		'쉽다',
		'짓다',
		'또한',
		'이유',
		'또는',
		'남편',
		'세상',
		'작다',
		'타다',
		'대학',
		'작품',
		'상황',
		'두다',
		'상태',
		'이후',
		'당시',
		'문학',
		'더욱',
		'아주',
		'지방',
		'높다',
		'최근',
		'현실',
		'환경',
		'먼저',
		'자체',
		'열다',
		'머리',
		'묻다',
		'남다',
		'부분',
		'기업',
		'거기',
		'변화',
		'아들',
		'선거',
		'그냥',
		'거의',
		'중심',
		'활동',
		'오늘',
		'서로',
		'관심',
		'역시',
		'이거',
		'광고',
		'나다',
		'정신',
		'아침',
		'웃다',
		'현상',
		'두다',
		'기술',
		'전체',
		'그래',
		'얻다',
		'민족',
		'조사',
		'영화',
		'필요',
		'하늘',
		'타일',
		'후잉',
		'투블루',
		'가계부',
		'아니다',
		'대하다',
		'말하다',
		'위하다',
		'그러나',
		'그렇다',
		'나오다',
		'따르다',
		'그리고',
		'못하다',
		'모르다',
		'만들다',
		'어떻다',
		'통하다',
		'그러다',
		'보이다',
		'가지다',
		'지나다',
		'이렇다',
		'어머니',
		'의하다',
		'버리다',
		'퍼센트',
		'대통령',
		'그래서',
		'그런데',
		'아버지',
		'다르다',
		'만나다',
		'보이다',
		'새롭다',
		'나가다',
		'이야기',
		'선생님',
		'느끼다',
		'어렵다',
		'부르다',
		'밝히다',
		'내리다',
		'생기다',
		'가운데',
		'보내다',
		'따라서',
		'컴퓨터',
		'다니다',
		'얼마나',
		'관하다',
		'나누다',
		'이르다',
		'이루다',
		'떠나다',
		'그대로',
		'듯하다',
		'오히려',
		'마시다',
		'나가다',
		'끝나다',
		'걸리다',
		'지키다',
		'대부분',
		'오르다',
		'세우다',
		'향하다',
		'분위기',
		'바꾸다',
		'열리다',
		'그리다',
		'배우다',
		'비하다',
		'만하다',
		'나서다',
		'흐르다',
		'하지만',
		'올리다',
		'그동안',
		'갑자기',
		'지니다',
		'잘하다',
		'어리다',
		'가능성',
		'목소리',
		'힘들다',
		'못하다',
		'돌리다',
		'모으다',
		'가지다',
		'지난해',
		'할머니',
		'바라다',
		'강하다',
		'아프다',
		'놀라다',
		'지내다',
		'모이다',
		'빠르다',
		'스스로',
		'아저씨',
		'제대로',
		'던지다',
		'마지막',
		'벌이다',
		'그러면',
		'가깝다',
		'열심히',
		'일하다',
		'당하다',
		'즐기다',
		'변하다',
		'그래도',
		'아무리',
		'맞추다',
		'버리다',
		'붙이다',
		'마치다',
		'빠지다',
		'오늘날',
		'인하다',
		'어쩌다',
		'옮기다',
		'바뀌다',
		'심하다',
		'자라다',
		'구체적',
		'소비자',
		'반드시',
		'갖추다',
		'꺼내다',
		'노동자',
		'언제나',
		'완전히',
		'자동차',
		'전하다',
		'어린이',
		'정하다',
		'한마디',
		'괜찮다',
		'피하다',
		'거치다',
		'여전히',
		'드리다',
		'걸치다',
		'아파트',
		'실제로',
		'고맙다',
		'들리다',
		'달리다',
		'바쁘다',
		'나쁘다',
		'시키다',
		'기르다',
		'줄이다',
		'원하다',
		'관계자',
		'티브이',
		'키우다',
		'알리다',
		'즐겁다',
		'싸우다',
		'예쁘다',
		'맛있다',
		'아무런',
		'미치다',
		'쓰레기',
		'무섭다',
		'우리나라',
		'중요',
		'사용',
		'기다림',
		'떨어짐',
		'이용',
		'프로그램',
		'가능',
		'주장',
		'이해',
		'마찬가지',
		'시작',
		'설명',
		'다양성',
		'강조',
		'요구',
		'할아버지',
		'재미',
		'표현',
		'발생',
		'비슷',
		'사랑',
		'실시',
		'그러니까',
		'존재',
		'유지',
		'공부',
		'발견',
		'마련',
		'발표',
		'비롯',
		'인정',
		'확인',
		'아나운서',
		'제시',
		'불과',
		'엄청',
		'텔레비전',
		'파악',
		'노력',
		'센티미터',
		'분명',
		'훌륭',
		'발달',
		'발전',
		'등장',
		'느낌',
		'정확',
		'가인박명',
		'각양각색',
		'간담상조',
		'감탄고토',
		'거두절미',
		'견리사의',
		'견위수명',
		'경국지색',
		'계군일학',
		'고량진미',
		'공중누각',
		'관포지교',
		'교언영색',
		'구미속초',
		'구우일모',
		'군계일학',
		'권모술수',
		'금석뇌약',
		'금지옥엽',
		'낙양지귀',
		'남가일몽',
		'내우외환',
		'노마지지',
		'천차만별',
		'누란지위',
		'단기지계',
		'진퇴유곡',
		'회자정리',
		'동가홍상',
		'동병상련',
		'마부위침',
		'망년지교',
		'망운지정',
		'맹모단기',
		'명경지수',
		'환과고독',
		'무산지몽',
		'물실호기',
		'박학다식',
		'방약무인',
		'백년해로',
		'천우신조',
		'부중지어',
		'이루어지다',
		'이야기하다',
		'받아들이다',
		'이데올로기',
		'자연스럽다',
		'비디오스튜',
	],
	en: [
		'the',
		'of',
		'to',
		'and',
		'in',
		'is',
		'it',
		'you',
		'that',
		'he',
		'was',
		'for',
		'on',
		'are',
		'with',
		'as',
		'his',
		'they',
		'be',
		'at',
		'one',
		'have',
		'this',
		'from',
		'or',
		'had',
		'by',
		'hot',
		'but',
		'some',
		'what',
		'there',
		'we',
		'can',
		'out',
		'other',
		'were',
		'all',
		'your',
		'when',
		'up',
		'use',
		'word',
		'how',
		'said',
		'an',
		'each',
		'she',
		'which',
		'do',
		'their',
		'time',
		'if',
		'will',
		'way',
		'about',
		'many',
		'then',
		'them',
		'would',
		'write',
		'like',
		'so',
		'these',
		'her',
		'long',
		'make',
		'thing',
		'see',
		'him',
		'two',
		'has',
		'look',
		'more',
		'day',
		'could',
		'go',
		'come',
		'did',
		'my',
		'sound',
		'no',
		'most',
		'number',
		'who',
		'over',
		'know',
		'water',
		'than',
		'call',
		'first',
		'people',
		'may',
		'down',
		'side',
		'been',
		'now',
		'find',
		'any',
		'new',
		'work',
		'part',
		'take',
		'get',
		'place',
		'made',
		'live',
		'where',
		'after',
		'back',
		'little',
		'only',
		'round',
		'man',
		'year',
		'came',
		'show',
		'every',
		'good',
		'me',
		'give',
		'our',
		'under',
		'name',
		'very',
		'through',
		'just',
		'form',
		'much',
		'great',
		'think',
		'say',
		'help',
		'low',
		'line',
		'before',
		'turn',
		'cause',
		'same',
		'mean',
		'differ',
		'move',
		'right',
		'boy',
		'old',
		'too',
		'does',
		'tell',
		'sentence',
		'set',
		'three',
		'want',
		'air',
		'well',
		'also',
		'play',
		'small',
		'end',
		'put',
		'home',
		'read',
		'hand',
		'port',
		'large',
		'spell',
		'add',
		'even',
		'land',
		'here',
		'must',
		'big',
		'high',
		'such',
		'follow',
		'act',
		'why',
		'ask',
		'men',
		'change',
		'went',
		'light',
		'kind',
		'off',
		'need',
		'house',
		'picture',
		'try',
		'us',
		'again',
		'animal',
		'point',
		'mother',
		'world',
		'near',
		'build',
		'self',
		'earth',
		'father',
		'head',
		'stand',
		'own',
		'page',
		'should',
		'country',
		'found',
		'answer',
		'school',
		'grow',
		'study',
		'still',
		'learn',
		'plant',
		'cover',
		'food',
		'sun',
		'four',
		'thought',
		'let',
		'keep',
		'eye',
		'never',
		'last',
		'door',
		'between',
		'city',
		'tree',
		'cross',
		'since',
		'hard',
		'start',
		'might',
		'story',
		'saw',
		'far',
		'sea',
		'draw',
		'left',
		'late',
		'run',
		'while',
		'press',
		'close',
		'night',
		'real',
		'life',
		'few',
		'stop',
		'open',
		'seem',
		'together',
		'next',
		'white',
		'children',
		'begin',
		'got',
		'walk',
		'example',
		'ease',
		'paper',
		'often',
		'always',
		'music',
		'those',
		'both',
		'mark',
		'book',
		'letter',
		'until',
		'mile',
		'river',
		'car',
		'feet',
		'care',
		'second',
		'group',
		'carry',
		'took',
		'rain',
		'eat',
		'room',
		'friend',
		'began',
		'idea',
		'fish',
		'mountain',
		'north',
		'once',
		'base',
		'hear',
		'horse',
		'cut',
		'sure',
		'watch',
		'color',
		'face',
		'wood',
		'main',
		'enough',
		'plain',
		'girl',
		'usual',
		'young',
		'ready',
		'above',
		'ever',
		'red',
		'list',
		'though',
		'feel',
		'talk',
		'bird',
		'soon',
		'body',
		'dog',
		'family',
		'direct',
		'pose',
		'leave',
		'song',
		'measure',
		'state',
		'product',
		'black',
		'short',
		'numeral',
		'class',
		'wind',
		'question',
		'happen',
		'complete',
		'ship',
		'area',
		'half',
		'rock',
		'order',
		'fire',
		'south',
		'problem',
		'piece',
		'told',
		'knew',
		'pass',
		'farm',
		'top',
		'whole',
		'king',
		'size',
		'heard',
		'best',
		'hour',
		'better',
		'TRUE',
		'during',
		'hundred',
		'am',
		'remember',
		'step',
		'early',
		'hold',
		'west',
		'ground',
		'interest',
		'reach',
		'fast',
		'five',
		'sing',
		'listen',
		'six',
		'table',
		'travel',
		'less',
		'morning',
		'ten',
		'simple',
		'several',
		'vowel',
		'toward',
		'war',
		'lay',
		'against',
		'pattern',
		'slow',
		'center',
		'love',
		'person',
		'money',
		'serve',
		'appear',
		'road',
		'map',
		'science',
		'rule',
		'govern',
		'pull',
		'cold',
		'notice',
		'voice',
		'fall',
		'power',
		'town',
		'fine',
		'certain',
		'fly',
		'unit',
		'lead',
		'cry',
		'dark',
		'machine',
		'note',
		'wait',
		'plan',
		'figure',
		'star',
		'box',
		'noun',
		'field',
		'rest',
		'correct',
		'able',
		'pound',
		'done',
		'beauty',
		'drive',
		'stood',
		'contain',
		'front',
		'teach',
		'week',
		'final',
		'gave',
		'green',
		'oh',
		'quick',
		'develop',
		'sleep',
		'warm',
		'free',
		'minute',
		'strong',
		'special',
		'mind',
		'behind',
		'clear',
		'tail',
		'produce',
		'fact',
		'street',
		'inch',
		'lot',
		'nothing',
		'course',
		'stay',
		'wheel',
		'full',
		'force',
		'blue',
		'object',
		'decide',
		'surface',
		'deep',
		'moon',
		'island',
		'foot',
		'yet',
		'busy',
		'test',
		'record',
		'boat',
		'common',
		'gold',
		'possible',
		'plane',
		'age',
		'dry',
		'wonder',
		'laugh',
		'thousand',
		'ago',
		'ran',
		'check',
		'game',
		'shape',
		'yes',
		'hot',
		'miss',
		'brought',
		'heat',
		'snow',
		'bed',
		'bring',
		'sit',
		'perhaps',
		'fill',
		'east',
		'weight',
		'language',
		'among',
	],
};

export default words;
