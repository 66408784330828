<script>
	import { onMount } from 'svelte';
	import Trans from './Trans.svelte';
	import { now, fastestSpeed, screenshot } from './store.js';
	onMount(() => {
		now.init();
	});

	let formattedTime = '';
	$: {
		const date = new Date($now);
		const hours = '0' + date.getHours();
		const minutes = '0' + date.getMinutes();
		const seconds = '0' + date.getSeconds();

		formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);
	}
</script>

<div id="footer">
	<div id="btn-screenshot" on:click={screenshot}>
		<Trans key="screenshot" />(Ctrl+S)
	</div>
	<div>
		<Trans
			ko="단문 최고"
			en="Fastest Sentence"
			append=" : "
		/>{$fastestSpeed.short}
		<Trans key="ta" />
	</div>
	<div>
		<Trans
			ko="장문 최고"
			en="Fastest Story"
			append=" : "
		/>{$fastestSpeed.long}
		<Trans key="ta" />
	</div>
	<a
		id="btn-sponsor"
		href="https://whooing.com?utm_source=typing"
		target="_blank"
		style="text-decoration:none;"
	>
		<Trans ko="제공" en="Sponsor" append=" : " /><Trans
			ko="후잉가계부"
			en="whooing.com"
		/>
	</a>
</div>

<style lang="scss">
	#footer {
		position: fixed;
		z-index: 10000;
		bottom: 0;
		left: 0;
		right: -2px;
		display: flex;
		justify-content: space-evenly;
		> div,
		a {
			border: 2px solid #f4f4f4;
			border-color: var(--txt-color);
			margin-left: -2px;
			padding: 0.05rem 0.3rem;
			display: flex;
			flex: 1;
		}
	}

	@media (max-width: 820px) {
		#footer {
			:global(#btn-screenshot),
			:global(#btn-sponsor) {
				display: none;
			}
		}
	}
	@media (max-width: 680px) {
		#footer {
			:global(.trans) {
				display: none;
			}
		}
	}
</style>
