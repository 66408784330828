<script>
	import { onMount } from 'svelte';
	import routes from './routes.js';
	import colors from './colors.js';
	import { settings } from './settings.js';
	import Index from './Index.svelte';
	import MainMenu from './MainMenu.svelte';
	import ShortList from './ShortList.svelte';
	import Short from './Short.svelte';
	import MiddleList from './MiddleList.svelte';
	import Middle from './Middle.svelte';
	import LongList from './LongList.svelte';
	import Long from './Long.svelte';
	import GameList from './GameList.svelte';
	import LevelList from './LevelList.svelte';
	import Venice from './Venice.svelte';
	import Area from './Area.svelte';
	import Flight from './Flight.svelte';
	import Setting from './Setting.svelte';
	import SettingLanguage from './SettingLanguage.svelte';
	import SettingColor from './SettingColor.svelte';
	import SettingFont from './SettingFont.svelte';
	import SettingSound from './SettingSound.svelte';
	import About from './About.svelte';
	import Note from './Note.svelte';
	import Footer from './Footer.svelte';

	const routeComponent = {
		shortlist: ShortList,
		short: Short,
		middlelist: MiddleList,
		middle: Middle,
		longlist: LongList,
		long: Long,
		setting: Setting,
		gamelist: GameList,
		levellist: LevelList,
		venice: Venice,
		area: Area,
		flight: Flight,
		about: About,
		note: Note,
		language: SettingLanguage,
		color: SettingColor,
		font: SettingFont,
		sound: SettingSound,
	};

	const isDesktop =
		navigator.userAgent.toLowerCase().indexOf('desktop') > -1 ||
		typeof window.todesktop !== 'undefined';
	const isStandalone =
		!!window.matchMedia &&
		window.matchMedia('(display-mode: standalone)').matches;
	const alreaySelectOnline =
		sessionStorage.tryitonline !== undefined &&
		!!sessionStorage.tryitonline;
	// let showIndex = true;
	let showIndex = false;
	if (isDesktop || window.isTouch || isStandalone || alreaySelectOnline) {
		showIndex = false;
	}


	function handleTry() {
		sessionStorage.tryitonline = true;
		showIndex = false;
	}

	const ua = navigator.userAgent.toLowerCase();
	const isSafari = false; // ua.indexOf('safari') != -1 && !(ua.indexOf('chrome') != -1);

	let colorSet;
	$: {
		const colorIndex = colors.findIndex((r) => r._id === $settings.color);
		if (colorIndex === -1) {
			colorSet = colors[0];
		} else {
			colorSet = colors[colorIndex];
		}
	}

	onMount(() => {
		window.routes.init({
			callback: (segments) => {},
		});
	});
</script>

<main
	class="{$settings.font} {$settings.language}"
	style="--txt-color: {colorSet.txt};--bg-color: {colorSet.bg};"
>
	{#if isSafari}
		<div id="alert-safari">
			죄송합니다. 데스크탑 크롬을 이용하여 주십시오.
		</div>
	{:else if showIndex}
		<Index {handleTry} />
	{:else}
		<Footer />
		<div id="frame-0" class="frame">
			<MainMenu />
		</div>
		{#each $routes as route, index}
			<div
				id="frame-{index + 1}"
				class="frame"
				data-route={route.component}
			>
				<svelte:component
					this={routeComponent[route.component]}
					params={route.params}
				/>
			</div>
		{/each}
	{/if}
</main>

<style>
	#alert-safari {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		color: #fff;
		background: #000;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
