<script>
	import { settings } from './settings.js';
	import Trans from './Trans.svelte';
</script>

<div id="setting" class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
	</div>
	<div class="frame-content">
		<a
			on:click={window.routes.push.bind(null, 'language')}
			class="btn active"
		>
			<label for=""
				><Trans ko="언어(Language)" en="Language(언어)" /></label
			>
			<span><Trans key="code" /></span>
		</a>
		<a on:click={window.routes.push.bind(null, 'color')} class="btn">
			<label for=""><Trans ko="색상" en="Color" /></label>
			<span>{$settings.color}</span>
		</a>
		<a on:click={window.routes.push.bind(null, 'font')} class="btn">
			<label for=""><Trans ko="서체" en="Font" /></label>
			<span><Trans key={$settings.font} /></span>
		</a>
		<a on:click={window.routes.push.bind(null, 'sound')} class="btn">
			<label for=""><Trans ko="효과음" en="Sound" /></label>
			<span><Trans key={$settings.sound} /></span>
		</a>
	</div>
</div>

<style lang="scss">
	#setting.frame-inner {
		width: 500px;
		.frame-content {
			.btn {
				display: flex;
				justify-content: space-between;
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
	}
</style>
