const langs = {
	code: {
		ko: '한국어',
		en: 'English',
	},
	sansserif: {
		ko: '둥근모',
		en: 'Dunguenmo',
	},
	sansserif2: {
		ko: '도스고딕',
		en: 'DosGothic',
	},
	serif1: {
		ko: '도스명조',
		en: 'DosMyungjo',
	},
	serif2: {
		ko: '삼국지3',
		en: 'Sam3KR',
	},
	d2coding: {
		ko: 'D2Coding',
		en: 'D2Coding',
	},
	saemmul: {
		ko: '도스샘물',
		en: 'DosSaemmul',
	},
	title: {
		ko: '온라인타자교실',
		en: 'Online Typing',
	},
	nextPage: {
		ko: '다음페이지',
		en: 'Next Page',
	},
	human: {
		ko: '나',
		en: 'Me',
	},
	computer: {
		ko: '상대방',
		en: 'Computer',
	},
	ta: {
		ko: '타',
		en: 'cpm',
	},
	y: {
		ko: '예',
		en: 'Yes',
	},
	n: {
		ko: '아니오',
		en: 'No',
	},
	area: {
		ko: '땅따먹기',
		en: 'Area War',
	},
	flight: {
		ko: '날아라 전투기',
		en: 'Flight',
	},
	venice: {
		ko: '유사 배네치아',
		en: 'Venice War',
	},
	level: {
		ko: '난이도',
		en: 'Level',
	},
	prevspeed: {
		ko: '이전속도',
		en: 'Prev Speed',
	},
	currentspeed: {
		ko: '현재속도',
		en: 'Current Speed',
	},
	precisie: {
		ko: '정확도',
		en: 'Precisie',
	},
	chars: {
		ko: '자',
		en: 'ch',
	},
	screenshot: {
		ko: '화면저장',
		en: 'Screenshot',
	},
};
export default langs;
