<script>
	import { settings } from './settings.js';
	import langs from './langs.js';

	export let params;
	$: {
		if (params[0] !== undefined) {
			settings.setIn('language', params[0]);
			window.routes.pop();
		}
	}
</script>

<div id="language" class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed">
			<span>&times;</span>
		</div>
	</div>
	<div class="frame-content">
		{#each Object.keys(langs.code) as langCode}
			<a
				on:click={window.routes.last.bind(null, `language.${langCode}`)}
				class="btn {$settings.language === langCode ? 'active' : ''}"
			>
				{langs.code[langCode]}
			</a>
		{/each}
	</div>
</div>

<style lang="scss">
	#language.frame-inner {
		width: 300px;
	}
</style>
