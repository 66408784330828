<script>
	import Trans from './Trans.svelte';
</script>

<div class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
	</div>
	<div class="frame-content">
		<a
			on:click={window.routes.push.bind(null, 'levellist.area')}
			class="btn active"><Trans key="area" /></a
		>
		<a
			on:click={window.routes.push.bind(null, 'levellist.venice')}
			class="btn"><Trans key="venice" /></a
		>
		<a
			on:click={window.routes.push.bind(null, 'levellist.flight')}
			class="btn"><Trans key="flight" /></a
		>
	</div>
</div>
