<script>
	import FlightPlay from './FlightPlay.svelte';

	let total = 0;
	export let params;
	let level = Number(params[0]);
</script>

<style lang="scss">
</style>

<div class="frame-inner full">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
	</div>
	<div class="frame-content">
		<FlightPlay level={level} total={total} />
	</div>
</div>
