<script>
	export let title = '';
	export let msg = '';
	export let callback;

	$: {
		window.__ALERT_CALLBACK = callback;
	}
</script>

<style lang="scss">
	#alert {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		background: #f4f4f4;
		background: var(--txt-color);
		border: 4px double #f4f4f4;
		border-color: var(--txt-color);
		padding: 1rem;
		text-align: center;
		color: #011375;
		color: var(--bg-color);
		max-width: 450px;
		box-shadow: 10px 20px 0 rgba(0, 0, 0, 0.62);
		z-index: 10;
		h1 {
			margin-top: 0;
		}
	}
</style>

{#if msg !== '' || title !== ''}
<div id="alert">
	{#if title !== ''}
	<h1>{title}</h1>
	{/if} {@html msg}
</div>
{/if}
