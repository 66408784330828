import App from './App.svelte';

if (location.hostname === 'typing.zidell.me') {
	// 운영모드에서는 콘솔 가림
	console.log(
		'%c[경고] 관리자의 요청이 아니면 이 기능을 사용하지 마십시오. 사용자의 정보를 탈취하거나 기타 악의적인 목적으로 활용될 수 있습니다.',
		'color: red; font-size: 20px; font-weight: bold;'
	);

	console.log = (...args) => {}; // do nothing
}

const app = new App({
	target: document.body,
	props: {
		name: 'world',
	},
});

export default app;
