<script>
	import { settings } from './settings.js';
	import Trans from './Trans.svelte';

	import data from './long.js';
	export let params;
	const ROWS_PER_PAGE = 9;

	let pageIndex;
	let isNext;
	let rows = [];
	$: {
		isNext = true;
		pageIndex = params[0] !== undefined ? Number(params[0]) : 0;
		settings.setIn('longPageIndex', pageIndex);
		rows = [];
		for (let i = 0; i < ROWS_PER_PAGE; i++) {
			const targetIndex = i + pageIndex * ROWS_PER_PAGE;
			if (data[$settings.language][targetIndex] !== undefined) {
				rows.push(data[$settings.language][targetIndex]);
			} else {
				isNext = false;
			}
		}
	}
</script>

<div class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed">
			<span>&times;</span>
		</div>
	</div>
	<div class="frame-content">
		{#each rows as row, index}
			<a
				on:click={window.routes.push.bind(null, `long.${row._id}`)}
				class="btn {index === 0 ? 'active' : ''}"
			>
				<span class="ellipsis text-start">{row.title}</span>
				<span>{row.content.length}<Trans key="chars" /></span>
			</a>
		{/each}
		{#if pageIndex > 0 || isNext}
			<a
				on:click={window.routes.last.bind(
					null,
					`longlist.${isNext ? pageIndex + 1 : 0}`
				)}
				class="btn"
			>
				<span><Trans key="nextPage" /> >></span>
				<span></span>
			</a>
		{/if}
	</div>
</div>

<style lang="scss">
	.frame-inner {
		max-width: 570px !important;
		a {
			padding-left: 1rem;
			padding-right: 1rem;
			display: flex;
			justify-content: space-between;
		}
	}
</style>
