import { writable } from 'svelte/store';
import _ from 'lodash';

export const settings = (() => {
	let browserLanguage = (navigator.language || navigator.userLanguage)
		.substring(0, 2)
		.toLowerCase();
	if (['en', 'ko'].indexOf(browserLanguage) === -1) {
		browserLanguage = 'ko';
	}
	const defaultSettings = {
		color: 'default',
		middle: {},
		middlePageIndex: 0,
		long: {},
		longPageIndex: 0,
		language: browserLanguage,
		font: 'sansserif',
		sound: 'y',
	};
	const savedSettings =
		localStorage.settings !== undefined
			? JSON.parse(localStorage.settings)
			: {};
	// 한때 사용되었었지만 현재는 사용되지 않는 옵션이 있다면 제거
	Object.keys(savedSettings).forEach((k) => {
		if (defaultSettings[k] === undefined) {
			delete savedSettings[k];
		}
	});
	const storeSettings = _.merge(defaultSettings, savedSettings);
	const { subscribe, update } = writable(storeSettings);

	const setIn = (k, v) => {
		update((store) => {
			if (typeof k === 'string') {
				k = [k];
			}

			const lastKey = k.pop();
			let target = store;
			k.forEach((o) => {
				target = target[o]; // 더 아래로 세분화해서 찾아감
			});
			target[lastKey] = v;

			localStorage.settings = JSON.stringify(store);
			return store;
		});
	};

	return {
		subscribe,
		setIn,
	};
})();
