<script>
	import { settings } from './settings.js';
	import Trans from './Trans.svelte';

	export let params;
	$: {
		if (params[0] !== undefined) {
			settings.setIn('sound', params[0]);
			window.routes.pop();
		}
	}
</script>

<div id="sound" class="frame-inner menu">
	<div class="frame-header">
		<div class="btn-closed">
			<span>&times;</span>
		</div>
	</div>
	<div class="frame-content">
		<a
			on:click={window.routes.last.bind(null, 'sound.y')}
			class="btn {$settings.sound === 'y' ? 'active' : ''}"
		>
			<Trans key="y" />
		</a>
		<a
			on:click={window.routes.last.bind(null, 'sound.n')}
			class="btn {$settings.sound === 'n' ? 'active' : ''}"
		>
			<Trans key="n" />
		</a>
	</div>
</div>

<style lang="scss">
	#sound.frame-inner {
		width: 300px;
	}
</style>
