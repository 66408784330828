const data = [
	{
		_id: 'count_star',
		title: '별 헤는 밤 - 윤동주',
		content: `별 헤는 밤
계절이 지나가는 하늘에는
가을로 가득 차 있습니다.
나는 아무 걱정도 없이
가을 속의 별들을 다 헬 듯합니다.
가슴 속에 하나 둘 새겨지는 별을
이제 다 못 헤는 것은
쉬이 아침이 오는 까닭이요
내일 밤이 남은 까닭이요
아직 나의 청춘이 다 하지 않은 까닭입니다.
별 하나에 추억과
별 하나에 사랑과
별 하나에 쓸쓸함과
별 하나에 동경과
별 하나에 시와
별 하나에 어머니, 어머니, 어머님, 나는 별 하나에 아름다운 말 한마디씩 불러 봅니다. 소학교 때 책상을 같이 했던 아이들의 이름과 패, 경, 옥, 이런 이국 소녀들의 이름과, 벌써 아기 어머니된 계집애들의 이름과, 가난한 이웃 사람들의 이름과, 비둘기, 강아지, 토끼, 노새, 노루, 프랑시스 잠, 라이너 마리아 릴케 이런 시인의 이름을 불러 봅니다.
이네들은 너무나 멀리 있습니다.
별이 아스라이 멀 듯이.
어머님,
그리고 당신은 멀리 북간도에 계십니다.
나는 무엇인지 그리워
이 많은 별빛이 내린 언덕 위에
내 이름자를 써 보고
흙으로 덮어 버리었습니다.
딴은 밤을 새워 우는 벌레는
부끄러운 이름을 슬퍼하는 까닭입니다.
그러나 겨울이 지나고 나의 별에도 봄이 오면
무덤 위에 파란 잔디가 피어나듯이
내 이름자 묻힌 언덕 우에도
자랑처럼 풀이 무성할거외다.`,
	},
	{
		_id: 'prologue',
		title: '서시 - 윤동주',
		content: `서시
죽는 날까지 하늘을 우러러
한 점 부끄럼이 없기를,
잎새에 이는 바람에도
나는 괴로워했다.
별을 노래하는 마음으로
모든 죽어가는 것을 사랑해야지
그리고 나한테 주어진 길을
걸어가야겠다.
오늘 밤에도 별이 바람에 스치운다.`,
	},
	{
		_id: 'penitence',
		title: '참회록 - 윤동주',
		content: `참회록
파란 녹이 낀 구리거울 속에
내 얼굴이 남아 있는 것은
어느 왕조의 유물이기에
이다지도 욕될까.
나는 나의 참회의 글을 한 줄에 줄이자.
---만 이십사년 일개월을
무슨 기쁨을 바라 살아왔던가.
내일이나 모레나 그 어느 즐거운 날에
나는 또 한 줄의 참회록을 써야 한다.
---그때 그 젊은 나이에
왜 그런 부끄런 고백을 했던가.
밤이면 밤마다 나의 거울을
손바닥으로 발바닥으로 닦아보자.
그러면 어느 운석 밑으로 홀로 걸어가는
슬픈 사람의 뒷모양이
거울 속에 나타나 온다.`,
	},
	{
		_id: 'self',
		title: '자화상 - 윤동주',
		content: `자화상
산모퉁이를 돌아 논가 외딴 우물을 홀로 찾아가선 가만히 들여다봅니다.
우물 속에는 달이 밝고 구름이 흐르고 하늘이 펼치고 파아란 바람이 불고 가을이 있습니다.
그리고 한 사나이가 있습니다.
어쩐지 그 사나이가 미워져 돌아갑니다.
돌아가다 생각하니 그 사나이가 가엾어집니다.
도로 가 들여다보니 사나이는 그대로 있습니다.
다시 그 사나이가 미워져 돌아갑니다.
돌아가다 생각하니 그 사나이가 그리워집니다.
우물 속에는 달이 밝고 구름이 흐르고 하늘이 펼치고 파아란 바람이 불고 가을이 있고 추억처럼 사나이가 있습니다.`,
	},
	{
		_id: 'easypoem',
		title: '쉽게 쓰여진 시 - 윤동주',
		content: `쉽게 쓰여진 시
창밖에 밤비가 속살거려
육첩방은 남의 나라
시인이란 슬픈 천명인 줄 알면서도
한 줄 시를 적어 볼까
땀내와 사랑내 포근히 품긴
보내 주신 학비 봉투를 받아
대학 노-트를 끼고
늙은 교수의 강의 들으러 간다.
생각해 보면 어린 때 동무를
하나, 둘, 죄다 잃어버리고
나는 무얼 바라
나는 다만, 홀로 침전하는 것일까?
인생은 살기 어렵다는데
시가 이렇게 쉽게 씌어지는 것은
부끄러운 일이다.
육첩방은 남의 나라
창밖에 밤비가 속살거리는데
등불을 밝혀 어둠을 조금 내몰고
시대처럼 올 아침을 기다리는 최후의 나
나는 나에게 적은 손을 내밀어
눈물과 위안으로 잡는 최초의 악수.`,
	},
	{
		_id: 'untildawn',
		title: '새벽이 올 때까지 - 윤동주',
		content: `새벽이 올 때까지
다들 죽어가는 사람들에게
검은 옷을 입히시오.
다들 살아가는 사람들에게
흰 옷을 입히시오.
그리고 한 침실에
가지런히 잠을 재우시오
다들 울거들랑
젖을 먹이시오
이제 새벽이 오면
나팔소리 들려 올 게외다.`,
	},
	{
		_id: 'hospital',
		title: '병원 - 윤동주',
		content: `병원
살구나무 그늘로 얼굴을 가리고, 병원 뒤뜰에 누워, 젊은 여자가 흰 옷 아래로 하얀 다리를 드러내 놓고 일광욕을 한다. 한나절이 기울도록 가슴을 앓는 다는 이 여자를 찾아오는 이, 나비 한 마리도 없다. 슬프지도 않은 살구나무 가지에는 바람조차 없다.
나도 모를 아픔을 오래 참다 처음으로 이 곳에 찾아 왔다. 그러나 나의 늙은 의사는 젊은이의 병을 모른다. 나한테는 병이 없다고 한다. 이 지나친 시련, 이 지나친 피로, 나는 성내서는 안 된다.
여자는 자리에서 일어나 옷깃을 여미고 화단에서 금잔화 한 포기를 따 가슴에 꽂고 병실 안으로 사라진다. 나는 그 여자의 건강이 ---- 아니 내 건강도 속히 회복되기를 바라며 그가 누웠던 자리에 누워 본다.`,
	},
	{
		_id: '',
		title: '길 - 윤동주',
		content: `길
잃어버렸습니다.
무얼 어디다 잃었는지 몰라
두 손의 호주머니를 더듬어
길에 나갑니다.
돌과 돌과 돌이 끝없이 연달아
길은 돌담을 끼고 갑니다.
담은 쇠문을 굳게 담아
길 위에 긴 그림자를 드리우고
길은 아침에서 저녁으로
저녁에서 아침으로 통했습니다.
돌담을 더듬어 눈물짓다
쳐다 보면 하늘은 부끄럽게 푸릅니다.
풀 한 포기 없는 이 길을 걷는 것은
담 저 쪽에 내가 남아 있는 까닭이고
내가 사는 것은 다만
잃은 것을 찾는 까닭입니다.`,
	},
	{
		_id: 'snowmap',
		title: '눈 오는 지도 - 윤동주',
		content: `눈 오는 지도
순이가 떠난다는 아침에 말 못할 마음으로 함박눈이 내려, 슬픈 것처럼 창 밖에 아득히 깔린 지도 위에 덮인다.
방 안을 돌아다 보아야 아무도 없다. 벽과 천정이 하얗다. 방 안에까지 눈이 내리는 것일까, 정말 너는 잃어버린 역사처럼 홀홀이 가는 것이냐. 떠나기 전에 일러둘 말이 있던 것을 편지를 써서도 네가 가는 곳을 몰라 어느 거리, 어느 마을, 어느 지붕 밑, 너는 내 마음 속에만 남아 있는 것이냐. 네 쪼그만 발자욱을 눈이 자꾸 내려 덮어 따라갈 수도 없다. 눈이 녹으면 남은 발자욱 자리마다 꽃이 피리니 꽃 사이로 발자욱을 찾아 나서면 일년 열두달 하냥 내 마음에는 눈이 내리리라.`,
	},
	{
		_id: 'returnnight',
		title: '돌아와 보는 밤 - 윤동주',
		content: `돌아와 보는 밤
세상으로부터 돌아오듯이 이제 내 좁은방에 돌아와 불을 끄옵니다.
불을 켜 두는 것은 너무나 괴로롭은 일이옵니다.
그것은 낮의 연장이옵기에-
이제 창문을 열어 공기를 바꾸어 들여야 할텐데
밖을 가만히 내다 보아야 방안과 같이 어두어 꼭 세상 같은데
비를 맞고 오는 길이 그대로 비속에 젖어 있사옵니다.
하로의 울분을 씻을바 없어 가만히 눈을 감으면
마음속으로 흐르는 소리,
이제,
사상이 능금처럼 저절로 익어 가옵니다.`,
	},
	{
		_id: 'anotherhome',
		title: '또 다른 고향 - 윤동주',
		content: `또 다른 고향
고향에 돌아온 날 밤에
내 백골이 따라와 한방에 누웠다.
어둔 방은 우주로 통하고
하늘에선가 소리처럼 바람이 불어 온다.
어둠 속에 곱게 풍화작용하는
백골을 들여다보며
눈물짓는 것이 내가 우는 것이냐
백골이 우는 것이냐
아름다운 혼이 우는 것이냐
지조 높은 개는
밤을 새워 어둠을 짖는다.
어둠을 짖는 개는
나를 쫓는 것일 게다.
가자 가자
쫓기우는 사람처럼 가자
백골 몰래
아름다운 또 다른 고향에 가자.`,
	},
	{
		_id: 'mosquito',
		title: '모기 - 한용운',
		content: `모기
모기여 그대는 범의 발톱이 없고
코끼리의 코가 없으나 날카로운 입이 있다.
그대는 다리도 길고 부리도 길고 날개도 쩌르지는 아니하다.
그대는 춤도 잘추고, 노래도 잘하고 피의 술도 잘도 잘먹는다.
사람은 사람의 피를 서로서로 먹는데
그대는 동족의 피를 먹지 아니하고 사람의 피를 먹는다.
아아, 천하만세를 위하야 바다같이 흘리는 인인지사의 피도 그대에게 맡겼거든
하물며 구구한 소장부의 쓸데없는 피야 무엇을 아끼리오.`,
	},
	{
		_id: 'mouse',
		title: '쥐 - 한용운',
		content: `쥐
나는 아무리 좋은 뜻으로 너를 말하여도
너는 작고 방정맞고 얄미운 쥐라고 밖에 할 수가 없다.
너는 사람의 결혼의상과 연회복을 낱낱이 조사하였다.
너는 쌀궤와 멱서리를 다 쪼고 물어내었다.
그 외에 모든 기구를 다 쪼아 놓았다.
나는 쥐덫을 만들고 고양이를 길러서 너를 잡겠다.
이 작고 방정맞고 얄미운 쥐야.
그렇다, 나는 작고 방정맞고 얄미운 쥐다.
나는 너희가 만든 쥐덫과 너희가 기른 고양이에게 잡힐 줄은 안다.
만일 내가 너희 의장과 창고를 통거리째 빼앗고
또 너희 집과 너희 나라를 빼앗으면
너희는 허리를 굽혀서 절하고 나의 공덕을 찬미할 것이다.
그리고 너희들의 역사에 나의 이름을 크게 쓸 것이다.
그러나 나는 그러한 큰 죄를 지을 만한 힘이 없다.
다만 너희들이 먹고 입고 쓰고 남은 것을 조금씩 얻어먹는다.
그래서 너희는 나를 작고 방정맞고 얄미운 쥐라고 하며
쥐덫을 만들고 고양이를 길러서 나를 잡으려한다.
나는 그것이 너희들의 철학이요 도덕인 줄을 안다.
그러나 쥐덫이 나의 덜미에 벼락을 치고 고양이의 발톱이 나의 옆구리에 샘을 팔 때까지
나는 먹고 마시고 뛰고 놀겠다.
이 크고 점잖고 귀염성 있는 인간들아.`,
	},
	{
		_id: 'fly',
		title: '파리 - 한용운',
		content: `파리
이 작고 더럽고 밉살스런 파리야
너는 썩은 쥐인지 만두인지 분간을 못하는 더러운 파리다.
너는 흰옷에는 검은 똥칠을 하고
검은 옷에는 흰 똥칠을 한다.
너는 더위에 시달려서 자는 사람의 단꿈을 깨워 놓는다.
너는 이 세상에 없어도 조금도 불가할 것이 없다.
너는 한눈 깜짝일 새에 파리채에 피칠하는 작은 생명이다.
그렇다. 나는 작고 더럽고 밉살스런 파리요, 너는 고귀한 사람이다.
그러나 나는 어여쁜 여왕의 입술에 똥칠을 한다.
나는 황금을 짓밟고 탁주에 발을 씻는다.
세상에 보검이 산같이 있어도 나의 털끝도 건드리지 못한다.
나는 설렁탕 집으로 궁중 연회에까지 상빈이 되어서 술도 먹고 노래도 부른다.
세상 사람은 나를 위하여 궁전도 짓고 음식도 만든다.
사람의 빈부 귀천을 물론하고 파리를 위하여 생긴 것이다.
너희는 나를 더럽다고 하지마는
너희들의 마음이야말로 나보다도 더욱 더러운 것이다.
그리하여 나는 마음이 없는 죽은 사람을 좋아한다.`,
	},
	{
		_id: 'homesickness',
		title: '향수 - 정지용',
		content: `향수
넓은 벌 동쪽 끝으로
옛이야기 지줄대는 실개천이 회돌아 나가고,
얼룩백이 황소가
해설피 금빛 게으른 울음을 우는 곳,
- 그 곳이 참하 꿈엔들 잊힐 리야.
절화로에 재가 식어지면
뷔인 밭에 밤바람 소리 말을 달리고,
엷은 졸음에 겨운 늙으신 아버지가
짚베개를 돋아 고이시는 곳,
- 그 곳이 참하 꿈엔들 잊힐 리야.
흙에서 자란 내 마음
파아란 하늘 빛이 그립어
함부로 쏜 화살을 찾으려
풀섶 이슬에 함추름 휘적시든 곳,
- 그 곳이 참하 꿈엔들 잊힐 리야.
전설바다에 춤추는 밤불결 같은
검은 귀밑머리 날리는 어린 누이와
아무렇지도 않고 여쁠 것도 없는
사철 발벗은 안해가
따가운 햇살을 등에 지고 이삭 줏던 곳,
- 그 곳이 참하 꿈엔들 잊힐 리야.
하늘에는 석근 별
알 수도 없는 모래성으로 발을 옮기고,
서리 까마귀 우지짖고 지나가는 초라한 지붕,
흐릿한 불빛에 돌아 앉어 도란도란거리는 곳,
- 그 곳이 참하 꿈엔들 잊힐 리야.`,
	},
	{
		_id: 'jagosaemyun',
		title: '자고 새면 - 임화',
		content: `자고 새면
- 벗이여 나는 이즈음 자꾸만 하나의 운명이란 것을 생각코 있다.
자고 새면
이변을 꿈꾸면서
나는 어느 날이나
무사하기를 바랐다
행복되려는 마음이
나를 여러 차례
주검에서 구해준 은혜를
잊지 않지만
행복도 즐거움도
무사한 그날그날 가운데
찾아지지 아니할 때
나의 생활은
꽃 진 장미넝쿨이었다
푸른 잎을 즐기기엔
나의 나이가 너무 어리고
마른 가지를 사랑키엔
더구나 마음이 애띠어
그만 인젠
살려고 무사하려던 생각이
믿기 어려워 한이 되어
몸과 마음이 상할
자리를 비워주는 운명이
애인처럼 그립다.`,
	},
	{
		_id: 'chungpodo',
		title: '청포도 - 이육사',
		content: `청포도
내 고장 칠월은
청포도가 익어가는 시절.
이 마을 전설이 주저리주저리 열리고
먼 데 하늘이 꿈꾸며 알알이 들어와 박혀
하늘 밑 푸른 바다가 가슴을 열고
흰 돛단배가 곱게 밀려서 오면
내가 바라는 손님은 고달픈 몸으로
청포를 입고 찾아 온다고 했으니,
내 그를 맞아, 이 포도를 따 먹으면
두 손은 함뿍 적셔도 좋으련.
아이야, 우리 식탁엔 은쟁반에
하이얀 모시 수건을 마련해 두렴.`,
	},
	{
		_id: 'flower',
		title: '꽃 - 이육사',
		content: `꽃
동방은 하늘도 다 끝나고
비 한 방울 내리잖는 그때에도
오히려 꽃은 빨갛게 피지 않는가
내 목숨을 꾸며 쉬임 없는 날이여
북쪽 툰드라에도 찬 새벽은
눈 속 깊이 꽃맹아리가 옴작거려
제비떼 까맣게 날아오길 기다리나니
마침내 저버리지 못한 약속이여
한바다 복판 용솟음 치는 곳
바람결 따라 타오르는 꽃 성에는
나비처럼 취하는 회상의 무리들아
오늘 내 여기서 너를 불러 보노라`,
	},
	{
		_id: 'gwangya',
		title: '광야 - 이육사',
		content: `광야
까마득한 날에
하늘이 처음 열리고
어디 닭 우는 소리 들렸으랴.
모든 산맥들이
바다를 연모해 휘달릴 때도
차마 이 곳을 범하던 못하였으리라.
끊임없는 광음을
부지런한 계절이 피어선 지고
큰 강물이 비로소 길을 열었다.
지금 눈 내리고
매화 향기 홀로 아득하니
내 여기 가난한 노래의 씨를 뿌려라.
다시 천고의 뒤에
백마 타고 오는 초인이 있어
이 광야에서 목놓아 부르게 하리라`,
	},
	{
		_id: 'sokmiin',
		title: '속모인곡 - 정철',
		content: `속미인곡
저기 가는 저 각시 본 듯도 하구나
천상의 수도 백옥경을 어찌하여 이별하고
해 다 저문 날에 누굴 보러 가시는가
어와 너로구나 내 이야기 들어보소
내 얼굴, 이 거동이 임에게 사랑받을만 하건마는
어딘지 날 보시고 너로구나 하고 여기시기에
나도 임을 믿어 다른 뜻이 전혀 없어
응석과 교태를 부리며 어지럽게 굴었던지
반기시는 낯빛이 옛날과 어찌 다르신가
누워 생각하고 일어나 앉아 헤아려보니
내 몸이 지은 죄가 산같이 쌓였으니
하늘을 원망하고 사람을 꾸짖으랴
서러워 풀어 헤아려보니 조물주의 탓이로다
그런 생각 마오 맺힌 일이 있소이다
임을 모시고 있어 임의 일을 내 알거니
물같던 얼굴이 편하실 적 몇날일까
봄 추위와 여름 더위는 어찌하며 지내시며
가을날과 겨울에는 누가 모셨는가
죽 조반과 아침 저녁 진지는 옛날과 같이 잘 잡수시는가
기나긴 밤에 잠은 어찌 자시는가
임 향한 소식을 어떻게든 알고자 하니
오늘도 거의 지나갔구나 내일이나 사람 올까
내 마음 둘 데 없다, 어디로 가자는 말인가
잡거니 밀거니 하며 높은 산에 올라가니
구름은 커녕 안개는 무슨 일인가
산천이 어두운데 해와 달은 어찌 보며
가까이를 모르거든 천 리는 어찌 보랴
차라리 물가에 가서 뱃길이나 보고자 하니
바람이야 물결로 어리둥절하게 되었구나
사공은 어디 가고 빈 배만 걸려있구나
강가에 홀로 서서 지는 해를 굽어 보니
임 향한 소식이 더욱 아득하구나
초가집 찬 자리로 밤중에 돌아오니
벽 가운데 걸린 푸른 등불은 누굴 위하여 밝아 있는가
오느락 내리락 헤매며 왔다갔다 하니`,
	},
	{
		_id: 'samiin',
		title: '사미인곡 - 정철',
		content: `사미인곡
이 몸이 태어날 때 님을 따라 태어나니
한평생 함께 살아갈 인연이며 하늘이 모를 일이겠는가
나는 단지 젊어 있고 님은 단지 나를 사랑하시니
이 마음과 이 사랑을 비교할 곳이 전혀 없다
평생에 원하건데 함께 살자고 하였더니
늙어서야 무슨 일로 외롭게 두고 그리워하는가
엊그제는 님을 모시고 광한전에 올랐더니
그동안 어찌하여 하계에 내려왔는가
내려올 때에 빗은 머리가 헝클어진지 삼년이다
연지와 분이 있지만 누구를 위하여 곱게 하겠는가
마음에 맺힌 시름이 겹겹히 쌓여 있어서
짓는 것은 한숨이고 떨어지는 것은 눈물이다
인생은 유한한데 시름은 끝이 없다
무심한 세월은 물 흐르듯 하는구나
더웠다 추워졌다 하는 것이 가는듯 다시 오니
듣거니 보거니 느낄 일이 많기도 많구나
봄바람이 문득 불어 쌓인 눈을 헤쳐내니
창 밖에 심은 매화 두세 가지가 피었구나
가뜩이나 쌀쌀한데 은은히 풍겨오는 향은 무슨 일인가
황혼에 달이 쫓아와 머리맡에 비치니
흐느끼는 듯 반기는 듯 님이신가 아니신가
저 매화를 꺾어내여 님 계신 곳에 보내고 싶다
님이 너를 보고 어떻다 여기실까
꽃 지고 새 잎이 나니 녹음이 깔렸는데
비단 휘장은 적막하고 수놓은 장막은 비어있다
연무늬 휘장을 걷어 놓고 공작을 수놓은 병풍을 둘러 두니
가뜩이나 시름이 많은데 날은 어찌 길던가
원앙 무늬 비단을 베어놓고 오색실을 풀어내어
금자로 재어서 님의 옷을 지어 내니
솜씨는 말할것도 없고 격식도 갖추었구나
산호수 지게 위의 백옥함에 담아두고
님엑 보내려고 님 계신 곳을 바라보니
산인지 구름인지 험하기도 험하구나
천만리나 되는 길을 누가 찾아갈까
가거든 열어 두고 나인가 반기실까
하룻밤 서리가 내리는데 기러기가 울며 날아갈 때
높은 누각에 혼자 올라 수정발을 걷으니
동산에 달이 뜨고 북극성이 보이니
님이신가 반가워하니 눈물이 절로 난다
밝은 빛을 쥐어서 봉황루에 보내고 싶다
누각 위에 걸어두고 온 세상을 다 비추어
깊은 산 험한 골짜기도 대낮같이 만드소서
천지가 닫혀버려 흰 눈이 가득할 때
사람은 물론이고 날아다니는 새도 보이지 않는다
따뜻한 남쪽도 추위가 이러한데
님 계신 곳이야 더욱 말해 무엇하리
따뜻한 봄기운을 부쳐 내어 님 계신 곳에 쏘이고 싶다
초가 처마에 비친 해를 옥루에 올리고 싶다
붉은 치마를 입고 푸른 소매를 반쯤 걷어 올려
해가 저문 후 길게 자란 대나무에 기대어 생각이 많기도 많구나
짧은 해가 쉬이 넘어가고 긴 밤을 고쳐 앉아
청등을 걸어둔 곁에 자개로 장식을 한 공후를 놓아 두고
꿈에서나 님을 보려 턱을 받치고 기대있으니
원앙 이불이 차기도 차구나, 이 밤은 언제 샐까
하루도 열두 때, 한 달도 서른 날
잠시라도 생각을 말고 이 시름을 잊으려 하는데
마음 속에 맺혀 있어 뼛속까지 사무쳤으니
편작이 열 명이 온다 해도 이 병을 어찌하리
아 내 병이야 이 님의 탓이로다
차라리 사라져서 호랑나비가 되리라
꽃나무 가지마다 가는 곳 족족 앉았다가
향 묻은 날개로 님의 옷에 앉으리라
님이야 나인 줄 모르셔도 나는 님을 좇으려 하노라`,
	},
	{
		_id: 'sungsan',
		title: '성산별곡 - 정철',
		content: `성산별곡
어떤 지나가는 손님이 성산에 머물면서
서하당 식영정 주인아, 내 말을 들으시오.
인생 세간에 좋은 일이 많건마는
어찌 당신은 강산을 갈수록 낫게 여겨
고요하고 쓸쓸한 산중에 들어가 나오시지 않는가?
소나무 밑동을 다시 쓸고 죽상에 자리를 만들어
잠시 잠깐 올라 앉아 어떠한가 다시 보니,
멀리 하늘가에 뜬 구름은 서석을 집을 삼아
나가는 듯 들어가는 모양이 주인의 풍류와 어떠한가
푸른 시내 흰 물결이 정자 앞에 둘러 있으니
천손운금(직녀가짠 아름다운 비단 곧, 은하수)을 누가 베어 내어
잇는 듯 펼쳐놓은 듯 야단스럽기도 야단스럽구나
산 속에 달력이 없어 네 계절을 모르고 지냈는데
눈 아래 펼쳐진 풍경이 철따라 저절로 생겨나니
듣고 보고 하는 것이 일마다 신선의 세계로다.
매화가 핀 창문에 비치는 이른 햇빛과 꽃향기에 잠을 깨니
선비가 할 일이 없지도 않구나.
울 밑 양지 쪽에 오이씨를 뿌려두고
그것을 매고 돋우고 비올 때는 가꾸어내니
청문고사가 지금도 있다고 하겠구나.
짚신을 바삐 신고 죽장을 옮겨 짚어가니
복숭아꽃 핀 시냇길이 방초주까지 이어졌구나.
잘 닦은 맑은 거울과 같은 물속에 저절로 그려진 바위 병풍
그림자를 벗으로 삼아 서하로 함께 가니
무릉도원이 어디인가, 여기가 별천지로구나
남풍이 문득 불어 녹음을 헤쳐 내니
계절을 아는 꾀꼬리는 어디에서 온 것인가?
희황 베개 위에 풋잠을 얼핏 깨니
공중에 서 있는 난간이 물 위에 떠 있는 듯 하구나
삼베옷을 걷어 올리고 칡을 만든 두건을 비껴쓰고
몸을 구부렸다 난간에 기대었다 하며 보는 것이 고기로구나.
하룻밤 내린 비에 홍백련이 섞어 피니,
바람 한 점 없어도 만산에 향기가 가득하구나
염계(주돈이)와 마주보고 우주의 원리를 묻는 듯
하늘의 선인이 옥자을 헤쳐 놓은 듯
노자암을 바라보며 자미탄(개울)을 곁에 두고
큰 소나무를 차일 삼아 돌바닥 길에 앉아보니
인간 세상의 유월이 여기는 늦가을처럼 선선하도다
푸른 강에 떠 있는 오리들이 흰 모래밭으로 옮겨앉아
흰 갈매기를 벗 삼아 잠 깰 줄 모르니
아무 잡념 없이 한가함이 주인과 (비교하면) 어떠한가
오동나무에 비친 달이 사경(새벽2시경)에 돋아 오르니
많은 바위와 계곡이 낮인들 어찌 그렇게 밝겠느냐?
호주(서호에 있는 섬) 수정궁을 누가 옮겨 온 것인가?
은하수를 건너 뛰어 광한전에 오른 듯
한 쌍의 늙은 소나무는 낚시터에 세워두고
그 아래 배를 띄워 가는 대로 던져두니
강가의 붉은 여귀꽃과 하얀 마름꽃을 어느 사이에 지났는지
환벽당 용의 연못(용추계곡)이 배 앞에 닿았구나
청강 푸른 풀 주위의 소먹이는 아이들이
석양에 흥에 넘쳐 조그만 피리를 비스듬히 대고 부니
물 아래 잠긴 용이 잠깨어 일어날 듯
안개 사이에 나온 학이 제 깃을 벌리고 하늘로 솟아 날아오를 듯하다.
소동파의 적벽(소동파가 적벽강에서 뱃놀이를 하고서 지은 글)은 가을 칠월이 좋다고 하나
팔월 십오야를 모두 어찌 칭찬하는가
엷고 고운 비단 같은 구름이 사방으로 걷혀가고 물결이 잔잔할 때
하늘에 돋은 달이 소나무 위에 걸려 있으니
강에 비친 달을 잡으려다 빠진 적선(이태백)이 한 짓이 야단스럽구나
외롭고 텅빈 산에 쌓인 낙엽을 걷어들이듯 북풍이 몹시 불어
떼구름 거느리고 눈조차 몰아 오니
조물주가 일 꾸미를 좋아하여 옥으로 눈꽃을 만들어
많은 나무와 수풀을 다시 꾸며 내는구나
앞 여울이 모두 얼어 외나무다리가 가로 걸렸는데
막대 멘 늙은 중이 어느 절로 간단 말인가
산 늙은이(김성원)의 풍류를 남들에게 떠들지 마시오
경요굴(달나라아름다운 구슬의 굴=성산) 은세계를 찾아오는 사람이 있지 않을까 걱정되는구나
산 속에 벗이 없어 서책을 쌓아두고
옛 시대의 인물을 거슬러 세어보니
성현도 많을 뿐만 아니라 호걸도 많도다
하늘이 사람을 만드실 때 아주 무심할까마는
어찌 한 시대의 운이 흥했다 망했다 하였는가
모를 일도 많거니와 애달픔도 끝이 없다.
기산의 늙은 고불(나이 많은 사람) 허유는 귀를 어떻게 씼었던가
표주박 하나도 귀찮다고 떨친 후에 지조행장이 더욱 높구나
인심이 얼굴 같아서 볼수록 새로운데
세상의 일은 구름과 같아서 험하고도 험하구나
엊그제 빚은 술이 얼마나 익었느냐?
주거니 받거니 실컷 마시고 나니
마음에 맺힌 근심이 조금 풀리는구나
거문고 줄에 시욹을 얹어 타니 풍입송 곡조가 끊어지지 않는구나
누가 손님인지 주인인지 다 잊어버렸구나
넓은 하늘에 떠 있는 학이 이 골짜기의 진짜 신선이라
옥으로 만든 누각 위, 달 아래에서 행여 그 신선을 만나지 않았는가?
손님이 주인에게 말하기를, "그대가 진짜 신선인가?"하고 묻는구나.`,
	},
	{
		_id: 'gwandong',
		title: '관동별곡 - 정철',
		content: `관동별곡
강호에 병이 깊어 죽림에 누웠더니,
관동 팔백 리의 관찰사를 맡기시네.
아아 나랏님 은혜 갈수록 그지없다.
	
연추문 들이달려 경회 남문 바라보며,
하직하고 물러나니 옥절이 앞에 섰다.
평구역 말을 갈아 흑수로 돌아드니,
섬강은 어데인고 치악산이 여기로다.
소양강 내린 물이 어디로 흘러드나.
서울 떠난 이내 머리 흰 서리로 덮였구나.
철원에서 밤새우고 북관정에 오르니,
삼각산 제일봉이 자칫하면 보이리라.
궁예 왕 대궐 터에 까막까지 지저귀니,
그 옛날의 흥망을 아느냐 모르느냐.
회양의 옛 이름이 여기와 같을시고.
선정 베푼 그 태수를 또다시 볼 것인가.
	
감영이 무사하고 절기는 삼월인데,
화천 시냇길이 금상산에 뻗어 있다.
길차림 잘 갖추고 돌길에 막대 짚어,
백천동 곁에 두고 만폭동 들어가니,
은 같은 무지개 옥 같은 용의 꼬리,
섞여 돌며 뿜는 소리 십 리에 잦아드니,
들을 때는 우레더니 보니까 눈이로다.
	
금강대 맨 위층에 학 두루미 새끼 치니,
봄바람 옥저 소리에 첫잠을 깨었는가.
나래 펼친 저 두루미 하늘 높이 솟아 떠서,
서호의 옛 주인을 반겨서 넘노는 듯.
소향로 대향로 눈 아래 굽어보고,
정양사 진헐대에 다시 올라앉으니,
금강산의 참모습이 여기서 다 뵈누나.
아아 조물주의 그 재간 놀랍기도 하구나.
날거든 뛰지 말고 섰거든 솟지나 말지.
연꽃을 꽂았는 듯 백옥을 묶었는 듯,
동해를 박차는 듯 북극을 괴었는 듯.
높을시고 망고대 외롭구나 혈망봉
하늘에 치밀어 무슨 일을 아뢰는가,
천만년 지나도록 굽힐 줄 모르누나.
아아 너로구나 너 같은 이 또 있는가.
	
개심대에 다시 올라 중향성 바라보며,
일만 이천 봉을 똑똑히 헤어 보니,
봉마다 맺혀 있고 끝마다 서린 기운,
맑거든 깨끗지 말고 깨끗커든 맑지 말지.
저 기운 흩어 내어 인걸을 만들고저.
그 모양 그지없고 생김새 하많구나.
하늘땅이 생겨날 때 저절로 되었건만,
이제 와 보게 되니 유정하기 그지없네.
	
비로봉 꼭대기에 올라 본 이 누구인가.
동산과 태산 어느 것이 더 높던가.
노나라 좁은 줄도 우리는 모르거든,
넓고도 넓은 천하 어찌하여 적다 했나.
아아 저 경계를 어이하면 알 것인가.
오르지 못하거니 내려감이 괴이할까.
	
원통골 좁은 길로 사자봉을 찾아가니,
그 앞에 너럭바위 화룡소가 되었구나.
천 년 묵은 늙은 용이 굽이굽이 서려 있어,
밤낮으로 흘러내려 먼 바다에 이었으니,
바람 구름 언제 얻어 단비를 내리려나.
음지 비탈 시든 풀을 다 살려 내다오.
	
마하연 묘길상 안문재 넘어가서,
외나무 썩은 다리 불정대에 올라 보니,
천 길 절벽을 반공중에 세워 두고,
은하수 큰 굽이를 마디마디 베어 내어,
실같이 풀어 헤쳐 삼베같이 걸었으니,
책에서는 열두 굽이 내 보기엔 여럿이라.
이태백이 이제 있어 고쳐 의논하게 되면,
여산이 여기보다 낫단 말 못 하리라.
	
산중을 매양 보랴 동해로 가자꾸나.
교자 타고 천천히 산영루에 올라가니,
맑은 시내 우는 새는 이별을 한하는 듯,
깃발을 떨치니 오색이 넘노는 듯,
풍악을 울리니 바다 구름 다 걷는 듯.
모랫길에 익은 말이 취한 신선 비껴 싣고,
바다를 곁에 두고 해당화 밭 들어가니,
갈매기야 날지 마라 네 벗인 줄 어찌 아느냐.
	
금란굴 돌아들어 총석정에 올라가니,
백옥루 남은 기둥 다만 넷이 서 있구나.
공수의 솜씨인가 귀신이 다듬었나.
구태여 여섯 면은 무엇을 뜻하는고.
고성을 저만치 두고 삼일포 찾아가니,
붉은 글자 완연하되 네 신선은 어데 갔노.
예서 사흘 머문 후에 또 어데 가 머물렀나.
선유담 영랑호 거기나 가 있는가.
청간정 만경대 몇 곳에 앉았던고.
	
배꽃은 벌써 지고 접동새 슬피 울 제,
낙산 동녘으로 의상대에 올라앉아,
해돋이를 보리라 밤중에 일어나니,
오색구름 피어난 듯 여섯 용이 받치는 듯,
바다를 떠날 제는 온 천하가 요동터니,
하늘 높이 치오르니 머리털도 헤리로다.
아마도 가는 구름 근처에 머물세라.
옛 시인 어데 가고 시만이 남았느냐.
천지간 장한 기별 자세키도 하구나.
	
석양 비낀 현산의 철쭉꽃 짓밟으며,
꽃수레 비껴 타고 경포로 내려가니,
십 리 폭 흰 비단을 다리고 또 다려서,
소나무 숲 속에다 끝없이 펼쳤으니,
물결도 잔잔하다 모래알을 헤리로다.
나루터에 배를 대고 정자 위에 올라가니,
강문교 넘어서 대양이 거기로다.
조용쿠나 그 기상 아득하다 저 경계.
이보다 나은 경치 또 어데 있단 말고.
홍장의 옛이야기 요란타 하리로다.
강릉 대도호의 풍속이 좋을시고.
충신 효자 기린 문이 동네마다 서 있으니,
착한 백성들이 이제도 있다 하리.
	
진주관 죽서루 오십천 내린 물이,
태백산 그림자를 동해로 담아 가니.
차라리 한강으로 서울 남산에 대었으면.
나그넷길 기한 있고 풍경이 싫지 않으니,
품은 회포 그윽하고 손의 시름 둘 데 없네.
신선의 떼 띄워 내어 별나라로 향해 갈까,
선인을 찾으러 단혈에 가 머무를까.
	
동쪽을 다 못 보고 망양정에 오르니,
바다 밖은 하늘인데 하늘 밖은 무엇인고.
가뜩이나 노한 고래(파도) 그 누가 놀래었나,
불거니 뿜거니 어지러이 구는구나.
은산을 꺾어 내어 온 세상을 뿌리는 듯,
오월 저 하늘에 흰 눈이 웬일인가.
	
잠깐 사이 밤에 들자 풍랑이 잦아들어,
동쪽 하늘에 달 뜨기를 기다리니,
환한 달빛이 보이는 듯 숨는구나.
구슬발 다시 걷고 옥섬돌 고쳐 쓸며,
샛별이 돋도록 바로 앉아 바라보니,
백련화 한 가지를 그 누가 보내었나.
이처럼 좋은 세계 남들께도 다 뵈고저.
유하주 가득 부어 달에게 묻는 말이,
"영웅은 어데 가고 네 신선 그 뉘신가."
아무나 만나 보아 옛일을 묻자니,
신선 산 동해는 갈 길도 멀고 머네.
	
솔뿌리를 베고 누워 풋잠이 얼핏 드니,
꿈에 한 사람이 날더러 하는 말이, 
"그대를 내 모르랴 하늘의 신선이라.
황정경 글 한 자를 어찌하여 잘못 읽고,
인간 세상 내려와서 우리를 따르는가.
잠깐만 가지 마오 이 술 한잔 먹어 보오."
	
북두칠성 기울여 창해 물을 부어서,
저 먹고 날 먹이며 서너 잔 마시니,
봄바람이 솔솔 불어 두 겨드랑이 추켜드네.
구만 리 장천에 좀 더하면 날리로다.
"이 술 가져다가 온 세상에 고루 나눠,
억만 백성 다 취케 만든 후에,
그때야 다시 만나 또 한잔 하자꾸나."
말 마치자 학을 타고 하늘로 올라가니,
공중 옥저 소리 어제런가 그제런가.
나도 잠을 깨어 바다를 굽어보니,
깊이를 모르거니 가인들 어찌 알리.
명월이 온 세상에 아니 비친 데 없다.`,
	},
	{
		_id: 'bongsun',
		title: '봉선화가 - 허난설헌',
		content: `봉선화가
규방에 할 일이 없어 백화보를 펼쳐 보니,
봉선화 이 이름을 누가 지어 냈는가.
신선의 옥피리 소리가 선경으로 사라진 후에,
규방에 남은 인연이 한 가지 꽃에 머물렀으니,
연약한 푸른 잎은 봉의 꼬리가 넘노는 듯하며,
아름다운 붉은 꽃은 신선의 옷을 펼쳐 놓은 듯하다.
고운 섬돌 깨끗한 흙에 촘촘히 심어 내니,
봄 삼월이 지난 후에 향기가 없다고 비웃지 마시오.
취한 나비와 미친 벌들이 따라올까 두려워서라네.
정숙하고 조용한 저 기상을 여자 외에 누가 벗하겠는가?
긴긴 날 옥난간에서 보아도 다 못 보아,
사창을 반쯤 열고 차환을 불러내어,
다핀 봉선화꽃을 따서 수상자에 담아 놓고,
바느질을 중단한 후 안채에 밤이 깊어,
밀촛불이 밝았을 때 차츰차츰 꼿꼿이 앉아,
흰 백반을 갈아 바수어 옥같이 고운 손 가운데 흐무러지게 개어 내니,
페르시아 제후가 좋아하는 붉은 산호 궁를 헤쳐 놓은 듯하며,
깊은 궁궐에서 절구에 붉은 도마뱀을 빻아 놓은 듯하다.
가늘고 고운 열 손가락에 수실로 감아 내니,
종이 위에 붉은 물이 희미하게 스며드는 모양은,
미인의 뺨 위에 홍조가 어리는 듯하며,
단단히 묶은 모양은 비단에 옥으로 쓴 편지를 서왕모에게 부치는 듯하다.
봄잠을 늦게 깨어 열 손가락을 차례로 풀어 놓고,
거울 앞에서 눈썹을 그리려고 하니,
난데없이 붉은 꽃이 가지에 붙어 있는 듯하여,
그것을 손으로 잡으려 하니 어지럽게 흩어지고
입으로 불려고 하니 입김에 가리워 보이지 않는다.
여자 친구를 불러서 즐겁게 자랑하고,
봉선화 앞에 가서 꽃과 손톱을 비교하니,
쪽 잎에서 나온 푸른 물이 쪽빛보다 푸르단 말, 이것이 아니 옳겠는가?
은근히 풀을 매고 돌아와서 누웠더니
푸른 저고리와 붉은 치마를 입은 한 여자가 홀연히 내 앞에 와서,
웃는 듯, 찡그리는 듯, 사례하는 듯, 하직하는 듯하다.
어렴풋이 잠을 깨어 곰곰이 생각하니,
아마도 꽃귀신이 내게 와서 하직을 고한 것이다.
수호를 급히 열고 꽃수풀을 살펴보니,
땅 위에 붉은 꽃이 떨어져서 가득히 수를 놓았다.
마음이 상해서 슬퍼하고 낱낱이 주워 담으며
꽃에게 말하기를 그대는 한스러워 마소.
해마다 꽃빛은 옛날과 같으며,
더구나 그대(봉선화) 자취가 내 손톱에 머물러 있지 않은가.
동산의 도리화는 잠깐 지나가는 봄을 자랑하지 마소.
이십사 번 꽃바람에 그대들(도리화)이 적막하게 떨어진들,누가 슬퍼하겠는가?
안방에 남은 인연이 그대 한 몸뿐일세.
봉선화 이 이름을 누가 지었는가? 이렇게 해서 지어진 것이로구나!`,
	},
	{
		_id: 'gyuwon',
		title: '규원가 - 허날설헌',
		content: `규원가
엊그제 젊었더니 하마 어이 다 늙거니
소년행락 생각하니 일러도 속절없다
늙어야 섦은 말씀 하자하니 목이 멘다
부생모육 신고하여 이내 몸 길러낼 제
공후배필 못 바라도 군자호구 원하더니
삼생의부업이오 월하의 연분으로
장안유협 경박자를 꿈 같이 만나있어
당시에 용심하기 살얼음 디디는 듯
삼오 이팔 겨우 지나 천연여질 절로 이니
이 얼굴 이 태도로 백년기약 하였더니
연광이 훌훌하고 조물이 다시하여
봄 바람 가을 달 베오리에 북 지나듯
설부화안 어디 가고 면목가증 되겠구나
내 얼굴 내 보거니 어느 님이 날 괼소냐
스스로 참괴하니 누구를 원망하리
삼삼오오 야유원에 새 사람이 나단말가
꽃 피고 날 저물 제 정처없이 나가 있어
백마금편으로 어디 어디 머무는고
원근을 모르거니 소식이야 더욱 알랴
인연을 그쳤은들 생각이야 없을소냐
얼굴을 못 보거든 그렇기나 말으려믄
열두 때 길도길샤 서른 날 지리하다
옥창에 심은 매화 몇 번이나 피어진고
겨울밤 차고 찬 제 자최눈 섞어 지고
여름날 깊고 길 제 궃은 비는 무슨 일고
상춘화유 호시절에 경물이 시름없다
가을 달 방에 들고 실솔이 상에 울 제
긴 한숨 지는 눈물 속절없이 헴만 많다
아마도 모진 목숨 죽기도 어려울사
도리어 풀쳐 헤니 이리하여 이리하리
	
청등을 돋아놓고 녹기금 빗겨 안아
접련화 한 곡조를 시름조차 섞어 타니
소상 야우에 댓 소리 섯도는 듯
화표 천년의 별학이 우니는 듯
옥수의 타는 수단 옛 소리 있다마는
부용장 적막하니 뉘 귀에 들릴소냐
간장이 구곡하여 구비구비 끊쳤어라
차라리 잠이 들어 꿈에나 보려하니
바람에 지는 잎과 풀 속에 우는 짐승
무슨 일 원수로서 잠조차 깨우는가
천상의 견우직녀 은하수 막혔어도
칠월칠석 일년일도 실기치 아니커든
우리님 가신 후는 무슨 약수 가렸관데
오거나 가거나 소식조차 끊쳤는고
난간에 빗겨 서서 님 가신 데 바라보니
초로는 맺혀 있고 모운이 지나갈 세
죽림 푸른 곳에 새 소리 더욱 섧다
세상에 섧은 사람 수없이 하려니와
박명한 홍안이야 날 같은 이 또 있을까
아마도 이 님의 지위로 살동말동 하여라`,
	},
	{
		_id: 'myungwol',
		title: '명월음 - 최현',
		content: `명월음
달아, 달아 밝은 달아. 청천에 떠 있는 달아. 얼굴은 언제 나며 밝기는 뉘 삼기뇨. 서산에 해 숨고 긴 밤이 침침한 때 청렴을 열어 놓고 보경을 닦아 내니 일편광휘에 팔방이 다 밝았다.
하룻밤 찬바람에 눈이 온가 서리 온가. 어이 한 천하가 백옥경이 되었는고. 동방이 채 밝거늘 수정렴을 걸어 놓고 거문고를 비껴 안아 봉황곡을 타 짚으니 소리마다 맑고 널리 퍼져 태공에 들어가니 달나라 계수나무 밑에 옥토끼도 돌아본다. 유리 호박주를 가득 부어 권하고자 하니 유정한 상아도 잔 밑에 빛난다. 청광을 머금으니 폐부에 흘러들어 호호한 흉중이 아니 비친 구멍 없다.
옷가슴 헤쳐 내어 광한전에 돌아앉아 마음에 먹은 뜻을 다 사뢰려 하였더니 마음 나쁜 부운이 어디서 와 가리었는고. 천지가 캄캄하여 온갖 사물을 다 못 보니 상하 사방에 갈 길을 모르겠다. 우뚝 선 산봉우리 끝에 달빛이 비치는 듯 운간에 나왔더니 떼구름이 미친 듯 나오니 희미한 한 빛이 점점 아득하여 온다. 중문을 닫아 놓고 뜰에 따로 서서 매화 한 가지 계수나무 그림자인가 돌아보니 처량한 암향이 날 좇아 근심한다. 소렴을 걷어 놓고 동방에 혼자 앉아 금작경 닦아 내어 벽 위에 걸어 두니 제 몸만 밝히고 남 비칠 줄 모른다.
둥근 비단 부채로 긴 바람 부쳐 내어 이 구름 다 걷고자. 기원 녹죽으로 일천 장 비를 매어 저 구름 다 쓸고자. 장공은 만 리오 이 몸은 진토니 엉성한 이내 뜻이 헤아려 보니 허사로다.
가뜩 근심 많은데 긴 밤이 어떠한고. 뒤척이며 잠 못 이뤄 다시금 생각하니 달이 차고 지며 초목이 자라고 스러짐이 천지도 무궁하니 풍운이 변화한들 본색이 어디 가료. 우리도 단심을 지켜서 명월 볼 날 기다리노라.`,
	},
];
export default data;
