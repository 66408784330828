<script>
	import { onMount } from 'svelte';
	import { settings } from './settings.js';
	import langs from './langs.js';
	import sound from './sound.js';
	import Hangul from 'hangul-js';
	import words from './words.js';
	import jQuery from 'jquery-slim';
	import _ from 'lodash';
	import Alert from './Alert.svelte';
	import levelData from './level-data.js';

	export let level = 1;

	const MAX_DISPLAYS = 5; // 화면에 두는 단어 갯수
	const MAX_TIME = 100; // 제한시간 초
	const MAX_STEP = 14; // 진행 단계
	const HURT_UNIT = 10; // 한 번 닳을 때 게이지
	const PROGRESS_INTERVAL = 2000;
	const COMPUTER_INTERVAL = levelData[level].delay;
	const KEY_SPACE = 32;
	const KEY_ENTER = 13;

	let bottomHeight = 10;
	let width = 10;
	let stepDistance = 10;
	let computerText = [];
	let computerTyped = [];
	let humanTyped = '';

	let alertTitle = '';
	let alertMsg = '';
	let alertCallback;
	let elapsedSeconds = MAX_TIME;
	let scores = {
		human: 100,
		computer: 100,
	};
	let displays = [];

	$: {
		if (/\s/.test(humanTyped)) {
			handleHumanTyping();
		}
	}

	function handleHumanTyping() {
		hit('human', humanTyped.split(/\s/)[0]);
		humanTyped = '';
	}

	function handleSubmit() {
		handleHumanTyping();
	}

	function generateBarArr(player) {
		const count = scores[player] / HURT_UNIT;
		return new Array(count);
	}

	function fillDisplays() {
		while (
			displays.filter((word) => word.side === '').length < MAX_DISPLAYS
		) {
			// 채워야하는 갯수만큼 반복
			const text = _.sample(words[$settings.language]);
			const index = displays.findIndex((r) => r.text === text);
			if (index === -1) {
				displays = [
					...displays,
					{
						text,
						side: '',
						left: _.random(0, width - 120),
						step: 0,
						distance: MAX_STEP * stepDistance,
					},
				];
			}
		}
	}

	// 단계를 다 채울 때까지 단어를 없애지 못했으니, 에너지가 닳는다
	function hurt(targetWord) {
		// displays에서 제거하기
		if (targetWord.side === 'human') {
			sound('wrong');
		} else {
			sound('success');
		}
		removeWordFromDisplays(targetWord.text);

		scores[targetWord.side] -= HURT_UNIT;

		if (scores[targetWord.side] <= 0) {
			endGame();
		}
	}

	let ended = false;
	function endGame(lose) {
		console.log('endGame');
		if (ended) {
			return false;
		}
		console.log('endGame2');
		ended = true;
		// 에너지가 더 작은 사람이 진 거임
		clearInterval(progressInterval);
		clearInterval(computerInterval);

		let status = 'lose';

		if (!!lose || scores.human < scores.computer) {
		} else if (scores.human > scores.computer) {
			status = 'win';
		} else {
			// 비겼을 때에는 서로의 방향에 가있는 단어수를 계산해서
			// 내가 덜 가지고 있으면 이긴다
			const humanWords = displays.filter(
				(word) => word.side === 'human'
			).length;
			const computerWords = displays.filter(
				(word) => word.side === 'computer'
			).length;
			if (humanWords < computerWords) {
				status = 'win';
			}
		}
		if (status === 'lose') {
			alertTitle = '패배!';
			alertMsg = levelData[level].lose;
			alertCallback = () => {
				window.routes.pop();
			};
		} else {
			alertTitle = '승리!';
			alertMsg = levelData[level].win;
			alertCallback = () => {
				window.routes.pop();
				if (level > 7) {
				} else {
					setTimeout(() => {
						window.routes.last('venice.' + (level + 1));
					}, 100);
				}
			};
		}
	}

	function removeWordFromDisplays(text) {
		// displays = _.filter(displays, (word) => word.text !== text);
		displays = displays.filter((word) => word.text != text);
	}

	function getDistance(word) {
		return (
			(MAX_STEP + word.step * (word.side === 'computer' ? 1 : -1)) *
			stepDistance
		);
	}

	// 타이핑을 완료한다
	function hit(mySide, text) {
		text = text.trim();
		console.log('text', text);
		let callback;
		displays = displays.map((word) => {
			if (word.text === text) {
				if (mySide === 'human') {
					sound('next');
				}
				if (word.side === '') {
					word.side = mySide === 'human' ? 'computer' : 'human'; // 상대방의 영역으로 보낸다
					word.step += 1;
					word.distance = getDistance(word);
				} else {
					callback = () => {
						removeWordFromDisplays(text);
					};
				}
			}
			return word;
		});
		if (typeof callback === 'function') {
			callback();
		}
	}

	function pickComputerText() {
		const targets = displays.filter((word) => word.side === 'computer');
		let word;
		if (targets.length > 0) {
			word = _.sortBy(targets, ['step']).pop();
		} else {
			word = _.sample(displays.filter((word) => word.side === ''));
		}
		if (word !== undefined) {
			computerText = Hangul.disassemble(word.text);
		}
	}

	function hitByComputer() {
		if (computerText.length === 0) {
			hit('computer', Hangul.assemble(computerTyped));
			computerTyped = [];
		} else {
			computerTyped = [...computerTyped, computerText.shift()];
		}
	}

	function progressStep() {
		let callback = [];
		displays = displays.map((word) => {
			if (word.side !== '') {
				// 이미 방향성이 생긴 놈을 더 강화
				word.step++;
				word.distance = getDistance(word);

				if (word.step >= MAX_STEP) {
					callback.push(() => {
						hurt(word);
					});
				}
			}
			return word;
		});

		callback.forEach((func) => {
			func();
		});

		fillDisplays();
	}

	let progressInterval;
	let computerInterval;
	onMount(() => {
		width = jQuery('#words-wrapper').width();
		bottomHeight = 27; //jQuery('#bottom').outerHeight();
		stepDistance =
			(jQuery('#words-wrapper').height() - bottomHeight) / (MAX_STEP * 2);

		// 진행을 위한 인터벌
		progressInterval = setInterval(() => {
			elapsedSeconds -= PROGRESS_INTERVAL / 1000;
			if (elapsedSeconds < 0) {
				elapsedSeconds = 0;
				endGame();
			}
			progressStep();
		}, PROGRESS_INTERVAL);

		// 컴퓨터 타이핑
		computerInterval = setInterval(() => {
			if (computerTyped.length > 0 || computerText.length > 0) {
				hitByComputer();
			} else {
				pickComputerText();
			}
		}, COMPUTER_INTERVAL);

		// human에 포커스
		jQuery('*[data-fucus="true"]').focus();

		return () => {
			clearInterval(progressInterval);
			clearInterval(computerInterval);
		};
	});
</script>

<div id="wrapper">
	<form on:submit|preventDefault={handleSubmit} class="player human">
		<input
			id="human"
			name="human"
			data-fucus="true"
			type="text"
			placeholder={langs.human[$settings.language]}
			autoComplete="off"
			autoCorrect="off"
			autoCapitalize="off"
			spellCheck="false"
			bind:value={humanTyped}
		/>
		<div class="bar">
			{#each new Array(Math.floor(scores.human / HURT_UNIT)) as c}
				<div />
				<div />
				<div />
				<div />
			{/each}
		</div>
	</form>

	<div id="words-wrapper" class="words">
		{#each displays as word (word.text)}
			<div
				class="word {word.side || 'center'}"
				style="left: {word.left}px; top: {word.distance}px;"
			>
				{word.text}
			</div>
		{/each}
	</div>

	<div id="computer-player" class="player computer">
		<input
			name="computer"
			type="text"
			placeholder={langs.computer[$settings.language]}
			autoComplete="off"
			autoCorrect="off"
			autoCapitalize="off"
			spellCheck="false"
			readonly
			value={Hangul.assemble(computerTyped)}
		/>
		<div class="bar">
			{#each new Array(Math.floor(scores.computer / HURT_UNIT)) as c}
				<div />
				<div />
				<div />
				<div />
			{/each}
		</div>
	</div>

	<div id="bottom" class="bottom-info">
		<div>Lv.{level}</div>
		<div>남은시간 : {parseInt(elapsedSeconds, 10)}s</div>
	</div>
</div>
<Alert title={alertTitle} msg={alertMsg} callback={alertCallback} />

<style lang="scss">
	#wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.words {
		flex: 1;
		position: relative;
		&::after {
			content: '';
			display: none;
			position: absolute;
			top: 50%;
			height: 2px;
			left: 0;
			right: 0;
			padding: 0;
			background: #f4f4f4;
			background: var(--txt-color);
		}
		.word {
			position: absolute;
			color: #011375;
			color: var(--bg-color);
			padding: 0 0.2rem;
			z-index: 1;
			border: 2px solid #011375;
			border-color: var(--bg-color);
			&.center {
				background: #f4f4f4;
				background: var(--txt-color);
				z-index: 2;
			}
			&.human {
				background: #ff3c01;
			}
			&.computer {
				background: #ffeb01;
			}
		}
	}

	.player {
		display: flex;

		input {
			border: 0;
			padding: 0.15rem;
			background: #000;
		}

		&.computer {
			bottom: -9px;
			border-top: 2px solid #f4f4f4;
			border-top-color: var(--txt-color);
			input {
				color: #ff3c01;
			}
		}

		&.human {
			border-bottom: 2px solid #f4f4f4;
			border-bottom-color: var(--txt-color);
			top: 0;
			input {
				color: #ffeb01;
			}
		}

		input {
			font-size: 1rem;
			box-shadow: none;
			outline: none;
			padding-left: 0.2rem;
		}

		.bar {
			flex: 1;
			display: flex;
			> div {
				background: #f4f4f4;
				background: var(--txt-color);
				width: 2.5%;
				opacity: 0.4;
				border: 1px solid #011375;
				border-color: var(--bg-color);
				border-top-width: 2px;
				border-bottom-width: 2px;
			}
		}
	}
</style>
