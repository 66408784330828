const data = {
	ko: [
		{
			title: '고전 속담',
			content: `가까운 이웃이 먼 친척보다 낫다
가난 구제는 나라님도 못한다
가난이 병보다 무섭다
가난한 집 제사 돌아오듯 한다
가는 방망이, 오는 홍두깨
가는 말이 고와야 오는 말이 곱다
가는 정이 있어야 오는 정이 있다
가랑비에 옷 젖는 줄 모른다
가랑잎으로 눈 가린다
가랑잎이 솔잎더러 바스락거린다고 한다
가을 중 싸대듯 한다
가지 많은 나무 바람 잘 날 없다
감나무 아래서 갓끈 메지 말고, 오이밭에서 신 끈 메지 마라
강 건너 불 보듯 한다
강철이 간 데는 가을도 봄이라
같은 값이면 다홍치마
같은 떡도 남의 것이 커 보인다
같은 말이라도 아 다르고 어 다르다
개같이 벌어서 정승같이 쓰랬다
개구리 올챙이 적 생각 못 한다
개똥도 약에 쓰려면 없다
개똥이 무서워 피하나, 더러워 피하지
개미 쳇바퀴 돌듯 한다
개울 치고 가재 잡는다
개 팔자가 상팔자라
계란에도 뼈가 있다
고기는 씹어야 맛이고 말은 해야 맛이다
고기도 저 놀던 물이 좋다
고기도 먹어 본 사람이 잘 먹는다
고래 싸움에 새우 등 터진다
고생 끝에 낙이 온다
고양이 목에 방울 달기
고양이 쥐 생각한다
고양이 보고 반찬가게 지켜 달란다
공든 탑이 무너지랴
구더기 무서워 장 못 담글까
구렁이 담 넘어가듯 한다
구르는 돌은 이끼가 끼지 않는다
구멍은 깎을수록 더 커진다
구슬이 서 말이라도 꿰어야 보배라
굴러온 돌이 박힌 돌 뺀다
굼벵이도 구르는 재주가 있다
굼벵이도 밟으면 꿈틀한다
굽은 나무가 선산을 지킨다
귀에 걸면 귀걸이, 코에 걸면 코걸이
그 아비에 그 자식이다
긁어 부스럼을 만든다
금강산도 식후경이라
급하다고 바늘허리에 실 매어 쓸까
기는 놈 위에 나는 놈이 있다
기둥을 치면 대들보가 운다
기름 먹인 가죽이 부드럽다
길고 짧은 것은 대어 보아야 안다
길러 준 개 주인 문다
길이 아니면 가지를 말고 말이 아니면 듣지를 말라
까마귀 날자 배 떨어진다
까마귀가 검기로 속도 검으랴
꾸어다 놓은 보릿자루
나간 놈의 집구석 같다
나 먹자니 싫고 남 주자니 아깝다
나 못 먹을 밥이라고 재 뿌린다
나무에 오르라 하고 흔든다
나무에 잘 오르는 놈이 나무에서 떨어지고, 헤엄 잘 치는 놈이 물에 빠져 죽는다
나중 난 뿔이 우뚝하다
나중 보자는 사람 무섭지 않다
남의 돈 천 냥이 내 돈 한 푼만 못하다
남의 제사에 감 놓아라 배 놓아라 한다
남의 집 금송아지가 우리 집 송아지만 못하다
남이야 전봇대로 이를 쑤시건 말건
낫 놓고 기역자도 모른다
낮말은 새가 듣고 밤말은 쥐가 듣는다
내가 할 말을 사돈이 한다
누울 자리 봐 가며 발 뻗는다
눈 감으면 코 베어갈 세상이다
늦게 배운 도둑이 날 새는 줄 모른다
다 된 밥에 재 뿌리기
다람쥐 쳇바퀴 돌듯 한다
단단한 땅에 물이 고인다
달리는 말에 채찍질한다
달면 삼키고 쓰면 뱉는다
닭 소 보듯 소 닭 보듯
닭 잡아먹고 오리발 내놓는다
닭 쫓던 개 지붕만 쳐다본다
도끼로 제 발등 찍는다
도둑을 맞을라니 개도 아니 짖는다
도둑질도 손발이 맞아야 한다
돌다리도 두들겨 보고 건너라
동냥은 아니 주고 쪽박만 깬다
되로 주고 말로 받는다
될성부른 나무는 떡잎부터 안다
두꺼비 파리 잡아먹듯 한다
들으면 병이요, 안 들으면 약이다
떡 줄 놈은 생각도 않는데 김칫국부터 마신다
똥 누러 갈 때 마음 다르고, 올 적 마음 다르다
똥 묻은 개가 겨 묻은 개 나무란다
똥이 무서워 피하나 더러워 피하지
마파람에 게눈 감추듯 한다
말은 나면 제주도로 보내고, 사람은 나면 서울로 보내라
말 안 하면 귀신도 모른다
말은 해야 맛이고 고기는 씹어야 맛이라
매도 먼저 맞는 놈이 낫다
먼 사촌보다 가까운 이웃이 낫다
모로 가도 서울만 가면 된다
못된 송아지 엉덩이에 뿔 난다
못 먹는 감 찔러나 본다
물에 빠지면 지푸라기라도 잡는다
물에 빠진 사람 건져 놓으니까 봇짐 내놓으라 한다
미꾸라지 한 마리가 온 웅덩이를 흐린다
미운 놈 떡 하나 더 준다
믿는 도끼에 발등 찍힌다
바늘 가는 데 실 간다
바늘 도둑이 소 도둑 된다
발 없는 말이 천 리 간다
밥 먹을 때는 개도 안 건든다
뱁새가 황새걸음을 걸으면 가랑이가 찢어진다
백지장도 맞들면 낫다
범 없는 골에서는 토끼가 왕 노릇 한다
범에게 물려가도 정신을 차려야 산다
법은 멀고 주먹은 가깝다
벙어리 냉가슴 앓듯 한다
부처님 가운데 토막 같다
불난 집에 부채질한다
비 온 뒤에 땅이 굳어진다
사공이 많으면 배가 산으로 올라간다
사또 떠난 뒤에 나팔 분다
사람은 죽어서 이름을 남기고, 호랑이는 죽어서 가죽을 남긴다
사서삼경을 다 읽어도 누울 와자가 제일
사촌이 땅을 사면 배가 아프다
산 사람의 목구멍에 거미줄 치랴
산이 울어 쥐 한 마리
살얼음을 밟는 것 같다
선무당이 사람 잡는다
세 살 적 버릇 여든까지 간다
소경이 개천을 나무란다
소경 제 닭 잡아먹기
소 잃고 외양간 고친다
숭어가 뛰니까 망둥이도 뛴다
신선놀음에 도끼자루 썩는 줄 모른다
십 년이면 강산도 변한다
싸움은 말리고 흥정은 붙이라 했다
싼 게 비지떡, 싼 게 갈치자반
아니 땐 굴뚝에 연기 날까
아이 보는 데서는 찬물도 못 마신다
안 되는 사람은 뒤로 넘어져도 코가 깨진다
앉은 자리에 풀도 안 나겠다
어물전 망신은 꼴뚜기가 시킨다
열 길 물속은 알아도 한 길 사람 속은 모른다
열두 가지 재주 가진 놈, 저녁거리가 없다
열 번 찍어 안 넘어가는 나무 없다
열 손가락을 깨물어서 안 아픈 손가락 없다
염불에는 마음이 없고 잿밥에만 마음이 있다
오는 정이 있어야 가는 정이 있다
오르지 못할 나무는 쳐다보지도 말라
용 꼬리가 되는 것보다 뱀 대가리가 되는 것이 낫다
우물에 가 숭늉 찾겠다
우물을 파도 한 우물을 파라
웃는 낯에 침 뱉으랴
윗물이 맑아야 아랫물도 맑다
원님 덕에 나팔 분다
원수는 외나무다리에서 만난다
이가 없으면 잇몸으로 산다
입술에 침이나 바르지
입은 비뚤어져도 말은 바로 해라
자라 보고 놀란 가슴, 솥뚜껑 보고도 놀란다
자식 겉 낳지 속 못 낳는다
잘 되면 제 탓, 안 되면 조상 탓
장부가 칼을 빼었다가 도로 꽂나
재수 없는 놈은 뒤로 자빠져도 코가 깨진다
재주는 곰이 넘고, 돈은 되놈이 번다
점잖은 개 부뚜막에 오른다
제 코가 석 자나 빠졌다
종로에서 뺨 맞고 한강에 가서 눈 흘긴다
죽 쑤어 개 좋은 일 하였다
중매는 잘 하면 술이 석 잔이고, 못 하면 뺨이 석 대라
중이 제 머리 못 깎는다
쥐구멍에도 볕들 날이 있다
지나가던 개가 웃겠다
지렁이도 밟으면 꿈틀한다
찬물도 위아래가 있다
참새가 방앗간을 그대로 지나랴
천 냥 빚도 말로 갚는다
천리 길도 한 걸음부터
첫 술에 배부르랴
참새가 작아도 알만 잘 깐다
칼을 꺼냈으면 무라도 잘라라
콩 심은 데 콩 나고 팥 심은 데 팥 난다
콩으로 메주를 쑨다 해도 곧이 안 듣는다
키 크고 싱겁지 않은 사람 없다
털도 안 뜯고 먹겠다 한다
털어서 먼지 안 나는 사람 없다
토끼가 제 방귀에 놀란다
팔이 안으로 굽지 밖으로 아니 굽는다
평양 감사도 저 싫으면 그만이다
품 안에 있어야 자식이라
핑계 없는 무덤 없다
하나를 보면 열을 안다
하늘을 보아야 별을 따지
하늘이 무너져도 솟아날 구멍이 있다
하던 지랄도 멍석 펴 놓으면 안 한다
하룻강아지 범 무서운 줄 모른다
한 번 실수는 병가의 상사
한 번 엎지른 물은 다시 주워 담지 못한다
한 술 밥에 배부르랴
해가 서쪽에서 뜨겠다
헌신짝 버리듯 한다
호랑이굴에 가야 호랑이 새끼를 잡는다
호랑이 담배 피우던 시절 이야기다
호랑이도 제 말 하면 온다
호박이 넝쿨째로 굴러떨어졌다
혹 떼러 갔다가 혹 붙이고 온다
혼자서 북 치고 장구 친다
화약을 지고 불속으로 들어간다
효자 집에 효자 난다`,
		},
		{
			title: '인터넷 속담',
			content: `나까지 나설 필요는 없다
헌신하면 헌신짝 된다
참고 참고 또 참으면 참나무가 된다
포기하면 편하다
왕관을 쓰려는 자 그 무게를 견뎌라
나도 나지만, 너도 너다
목숨을 버리면 무기만은 살려 주겠다
가는 말이 고우면 사람을 얕본다
잘생긴 놈은 얼굴값하고, 못생긴 놈은 꼴값한다
공부는 실수를 낳지만, 찍기는 기적을 낳는다
까도 내가 깐다
난 오아시스를 원했고, 넌 신기루만으로 좋았던 거지
동정할 거면 돈으로 줘라
즐길 수 없으면 피하라
이것 또한 지나가리라
대문으로 가난이 찾아오면, 사랑은 창문으로 도망간다
내 부모에게 욕하는 건 참아도, 나에게 욕하는 건 참을 수 없다
일찍 일어나는 새가 더 피곤하다
일찍 일어난 벌레는 잡아먹힌다
먼저 가는 건 순서가 없다
똥차 가고 벤츠 온다
먹는 것이 공부라면 세상에서 공부가 가장 좋다
어려운 길은 길이 아니다
개천에서 용 난 놈, 사귀거나 만나면 개천으로 빨려 들어간다
새벽에 맥주와 먹는 치킨은 0 칼로리다
이런 인생으론 자서전도 쓸 수 없다
늦었다고 생각할 때가 가장 늦은 거다
성형수술하고 나아진 게 아니라 하기 전이 최악이었다
내일 할 수 있는 일을 굳이 오늘 할 필요는 없다
남자는 애 아니면 개다
지금 쟤 걱정할 때가 아니다 내가 더 걱정이다
예술은 비싸고 인생은 더럽다
고생 끝에 골병 난다
하나를 보고 열을 알면 무당 눈깔이다
원수는 회사에서 만난다
돌다리도 두들겨 보면 내 손만 아프다
재주가 많으면 먹고살 만한 길이 많다
티끌 모아 봐야 티끌이다
참을 인이 세 번이면 호구다
못 올라갈 나무는 사다리 놓고 올라라
버스 지나간 뒤 손들면 백미러로 보고 선다
젊어서 고생은 늙어서 신경통이다
예술은 지루하고 인생은 아쉽다
호랑이한테 물려가도 죽지만 않으면 산다
윗물이 맑으면 세수하기 좋다
고생 끝에 병이 든다
아는 길은 곧장 가라
서당개 삼 년이면 보신탕감이다
동두천 개 삼 년이면 팝송을 듣는다
가다가 중지하면 간만큼 이득이다`,
		},
		{
			title: '사랑에 관한 명언',
			content: `사랑 받고 싶다면 사랑하라, 그리고 사랑스럽게 행동하라
우리는 오로지 사랑을 함으로써 사랑을 배울 수 있다
자신을 사랑하는 법을 아는 것이 가장 위대한 사랑입니다
더 많이 사랑하는 것 외에 다른 사랑의 치료약은 없다
중요한 것은 사랑을 받는 것이 아니라 사랑을 하는 것이었다
사랑하고 사랑 받는 것은 양 쪽에서 태양을 느끼는 것이다
자유를 사랑하는 것은 타인을 사랑하는 것이다. 권력을 사랑하는 것은 자신을 사랑하는 것이다
사랑을 하는 사람과 사랑을 받는 사람은 항상 따로 있어
사랑은 그저 미친 짓이예요
죄를 미워하되 죄인은 사랑하라
한 방향으로 깊이 사랑하면 다른 모든 방향으로의 사랑도 깊어진다
겁쟁이는 사랑을 드러낼 능력이 없다. 사랑은 용기 있는 자의 특권이다
사랑하는 것은 천국을 살짝 엿보는 것이다
사랑은 무엇보다도 자신을 위한 선물이다
사랑의 첫 번째 의무는 상대방에 귀 기울이는 것이다
세기의 사랑일지라도 참고 견뎌내야 한다
나이가 들어도 사랑을 막을 수는 없어요. 하지만 사랑은 노화를 어느 정도 막을 수 있죠
사랑은 우리가 기꺼이 피우는 폭발하는 시가이다
남편을 만나기 전, 사랑에 몇 번 발을 들여놓은 적은 있었지만 사랑에 빠진 적은 없었다
실제로 느끼지 못하는 사랑을 느끼는 척 하지 말라. 사랑은 우리가 좌지우지 할 수 없으므로
사랑은 지성에 대한 상상력의 승리다
사랑은 판단하지 않는다. 주기만 할 뿐이다
진정한 사랑은 영원히 자신을 성장시키는 경험이다
음식에 대한 사랑보다 더 진실된 사랑은 없다
누군가를 사랑한다는 것은 자신을 그와 동일시 하는 것이다
사랑은 눈으로 보지 않고 마음으로 보는 거지
사랑은 모두가 기대하는 것이다. 사랑은 진정 싸우고, 용기를 내고, 모든 것을 걸 만하다
나는 내가 아픔을 느낄만큼 사랑하면 아픔은 사라지고 더 큰 사랑만이 생겨난다는 역설을 발견했다
민주주의에 대한 사랑은 곧 평등에 대한 사랑이다
사랑은 증오의 소음을 덮어버리는 쿵쾅대는 큰 북소리다
서로를 용서하는 것이야말로 가장 아름다운 사랑의 모습이다
고로 사랑에 빠지지 말라. 네 얼굴에 달라 붙을 거야
단지 누구를 사랑한다고 해서 무조건 감싸야 한다는 뜻은 아니다. 사랑은 상처를 덮는 붕대가 아니다
내가 이해하는 모든 것은 내가 사랑하기 때문에 이해한다
만유인력은 사랑에 빠진 사람을 책임지지 않는다
사랑으로 행해진 일은 언제나 선악을 초월한다
얼마나 많이 주느냐보다 얼마나 많은 사랑을 담느냐가 중요하다
인생에 있어서 최고의 행복은 우리가 사랑 받고 있음을 확신하는 것이다
과도한 사랑은 인간에게 아무런 명예나 가치도 가져다주지 않는다
진실한 사람들의 결혼에 장해를 용납하지 않으리라. 변화가 생길 때 변하는 사랑은 사랑이 아니로다
모두를 사랑하되, 몇 사람만 믿으라. 누구에게도 잘못을 저지르지 말라
사랑은 거부할 수 없게 열망을 받으려는 거부할 수 없는 열망이다
열정은 세상을 돌게 한다. 사랑은 세상을 좀 더 안전한 곳으로 만들 뿐이다
사랑은 끝없는 용서의 행위이며, 습관으로 굳어지는 상냥한 표정이다
낱말 하나가 삶의 모든 무게와 고통에서 우리를 해방시킨다. 그 말은 사랑이다
사랑은 자신 이외에 다른 것도 존재한다는 사실을 어렵사리 깨닫는 것이다
우정이 바탕이 되지 않는 모든 사랑은 모래 위에 지은 집과 같다
진정한 사랑은 모든 것을 끄집어내요. 어느 새 매일 거울을 끄집어내 보고 있죠
진실을 사랑하고 실수를 용서하라
사랑에는 늘 어느 정도 광기가 있다. 그러나 광기에도 늘 어느 정도 이성이 있다
사랑이란 한 사람과 다른 모든 사람들 사이에 있는 차이를 심각하게 과장한 것이다
너희들이 나만큼 인생에 대해 알게 되면 강박적인 사랑의 힘을 과소평가하진 않을 게다
말이 아니라, 일이 사랑의 증거다
지식은 사랑이요, 빛이며, 통찰력이다
돈은 사랑의 핏줄이자 전쟁의 핏줄
섹스는 역사상 가장 아무 것도 아닌 일이다
키스하는 두 사람은 항상 물고기처럼 보인다
희망만이 인생을 유일하게 사랑하는 것이다
예술과 사랑을 하기에는 인생이 짧다
지혜로운 자는 사랑하고, 다른 모든 이는 욕망할 지니
우리는 사랑하는 친구들에 의해서만 알려진다
다른 사람들을 평가한다면 그들을 사랑할 시간이 없다
오직 남을 위해 산 인생만이 가치 있는 것이다
나는 맥주병 그리고 거울과 사랑에 빠졌을 뿐이다
이별의 아픔 속에서만 사랑의 깊이를 알게 된다
사랑, 돈, 명성보다는 진실을 내게 달라
모든 사람이 끝에는 엉뚱한 사람에게 굿나잇 키스를 하게 된다
세상을 자신의 몸처럼 사랑하는 사람에게는 제국을 맡길 수 있다
우리를 인정해주는 사람들을 어떻게 소중히 여기고 존경할까
가장 끔찍한 빈곤은 외로움과 사랑받지 못한다는 느낌이다
섹스는 침대 위에서보다 영화나 책으로 볼 때 더 흥분된다
짝사랑처럼 땅콩버터 맛을 떨어뜨리는 것은 아무것도 없어
가족이란 네가 누구 핏줄이냐가 아니야. 네가 누구를 사랑하느냐는 거야
마음에 대해 논할 때, 자기 기만에 대해서는 할 말이 많다
전쟁은 적을 사랑하라고 가르치지 않는다. 오히려 동맹군을 미워하라고 가르칠 뿐
함께 있을 때 웃음이 나오지 않는 사람과는 결코 진정한 사랑에 빠질 수 없다
다른 누군가가 되어서 사랑받기보다는 있는 그대로의 나로서 미움받는 것이 낫다
아, 이 사랑의 봄은 사월 어느 날의 변덕스런 영광을 닮았구나
나는 애정을 받을 엄청난 욕구와 그것을 베풀 엄청난 욕구를 타고났다
행복은 다른 도시에 서로 사랑하고 돌봐주며 친밀한 대가족을 두는 것이다
어린 아이들을 고통 받게 놔두는 한, 이 세상에 참된 사랑은 없다
아버지는 나에게 일을 하라고 가르치셨지만, 그 일을 사랑하라고 가르치지는 않았다
네 모습 그대로 미움 받는 것이 너 아닌 다른 모습으로 사랑 받는 것보다 낫다
키스해 주는 어머니도 있고 꾸중하는 어머니도 있지만 사랑하기는 마찬가지이다
격동은 생명력이다. 기회이다. 격동을 사랑하고, 변화를 위해 사용하자
선물로 친구를 사지마라. 선물을 주지 않으면 그 친구의 사랑도 끝날 것이다
순간을 사랑하라. 그러면 그 순간의 에너지가 모든 경계를 넘어 퍼져나갈 것이다
누군가 양을 갖고 싶어 한다면 그것은 그 사람이 이 세상에 존재한다는 증거다
진정한 책을 만났을 때는 틀림이 없다. 그것은 사랑에 빠지는 것과도 같다
내가 실제 악질은 아니다. 나는 스스로 다정한 사람이라 생각한다. 엄마를 사랑하니까
내 신체에 감사하는 것이 자신을 더 사랑하는 열쇠임을 비로소 깨달았습니다
당신이 행한 봉사에 대해서는 말을 아끼라. 허나 당신이 받았던 호의들에 대해서는 이야기하라
우리 어머니는 아이들을 사랑하셨다. 내가 어린애였다면 어머니는 뭐든 주셨을 것이다
서로에게 모든 것을 줄 때 평등한 거래가 된다. 각자가 모든 것을 얻게 된다
성공적인 결혼은 늘 똑같은 사람과 여러 번 사랑에 빠지는 것을 필요로 한다
세상에는 두 종류의 학자만 있다. 바로 사상을 사랑하는 학자와 사상을 혐오하는 학자이다
지식인이라면 적을 사랑할 수 있을 뿐 아니라 친구를 미워할 수도 있어야 한다
남의 말을 경청하는 사람은 어디서나 사랑받을 뿐 아니라 시간이 흐르면 지식을 얻게 된다
우리가 부모가 됐을 때 비로소 부모가 베푸는 사랑의 고마움이 어떤 것인지 절실히 깨달을 수 있다
급돈을 위해 당신의 일을 돕는 사람을 고용하기보다는 그 일을 사랑하는 사람을 고용하라
어머니가 아버지보다 자식을 더 사랑하는 이유는 아이가 자기 자식임을 더 확신하기 때문이다
정직은 서로의 피부 속까지 들어가서 살만큼 가까워질 수 있는 유일한 방법이다
노력으로 흘린 땀은 드러나지 않는다. 신들에게 사랑 받는다고 보이는 편이 훨씬 품위 있다
이 사랑의 꽃봉오리는 여름날 바람에 마냥 부풀었다가, 다음 만날 때엔 예쁘게 꽃필 거예요
친구의 사랑보다 더 오래가는 유일한 것은 서로에 대해 더 잘 알지 못하도록 막는 어리석음이다
올바른 원칙을 알기만 하는 자는 그것을 사랑하는 자와 같지 않으니라
자식은 우리에게서 얻어간 만큼 베푼다`,
		},
		{
			title: '기업 홍보문구',
			content: `복식부기로 돈의 흐름을 주도하라. 가계부 후잉
메시지만 적으면 카드뉴스와 동영상이 한 번에. 타일
우리 동네 중고 직거래 마켓. 당근마켓
크리에이티브 네트워크. 노트폴리오
길을 만들다. 현대 엘레베이터
여행은 살아보는 거야. 에어비앤비
정말 많이 해 본 사람 정말 오래 할 수 있는 사람. 금강기획
30대는 20대로 완성된다. 리쿠르트
소리 없이 세상을 움직입니다. 포스코
세상이 흔들려도 당신은 흔들리지 않도록. 현대철강
휴먼 르네상스 경영을 추구합니다. 태평양
진정한 후원은 후원이 끝나게 하는 것입니다. 월드비전
크게 생각하라. 아이맥스
기억될 90년에서 기대될 90년으로. 삼양홀딩스
줄이면 보이는 것이 있습니다. 에너지관리공단
서두르지 말고 그러나 쉬지는 말고. 포항재철
하지만 2등은 기억되지 않는다. 삼성
파는 사람들이 경쟁하면 가격은 내려갑니다. 옥션
생활까지 싱싱하게. 농수산홈쇼핑
정직한 가격. 독일산 면도날. 와이즐리
장보기를 새롭게 퀄리티 있게. 마켓컬리
매일 아침 상처받는 남자들을 위한 쉐이빙 혁명템. 젠틀프로젝트
나도 몰랐던 내 안의 콧물포텐. 이비인후과 찾을 때. 굿닥
한 달에 한 번 개린이날을 선물하세요. 반려견 용품 정기배송. 베이컨박스
수의사가 챙겨주는 반려견 종합 건강세트. 돌로박스
영어는 빈둥대는 거야. 슈퍼팬
금융이 쉬워진다. 토스
중개사는 있다. 수수료는 없다. 집토스
효과적인 팀워크 가벼워진 업무. 협업툴 잔디
10대의 몸에 가장 오래 닿아있는 가구. 시디즈
의자가 성적을 바꾼다. 시디즈
막힘없이 풀리는 국민안심 화장지. 크리넥스
아름다운 행복은 마루 위에서 시작됩니다. 이건마루
집사가 만든 고양이 전용 영양제. 케츠힐
럭셔리하게 효도하는 가장 쉬운 방법. 바디프랜드
찾아가던 맛집을 원하는 곳에서. 배달의민족
투명은 안심이다! 가그린
겨울옷 세탁할 때가 됐나 봄. 크린토피아
건강한 잠. 덮는 것만으로 충분합니다. 세사리빙
바퀴벌레 최후의 만찬. 동아제약 바킬라
엄마와 닥터가 아기 물티슈를 연구합니다. 유한킴벌리 닥터마밍
좋은 침대 바른 침구. 대진썰타침대
자연의 시간표대로. 해찬들
간장게장 깊이 벤 가을의 맛. 배민찬
다이어트는 포토샵으로. 배달의 민족
가루 커피가 아니다! 커피 원액 그대로 스틱에 담다. 한국야구르트
밥을 부르는 치킨. 굽네 갈비천왕
설탕 대신 설탕처럼. 백설 스위트리 알룰로스
컵나 좋군. 오뚜기 떠먹는 컵피자
세상에서 가장 작은 카페. 카누
오라. 상쾌함의 세계로. 스프라이트
포장지를 풀면 목도 풀립니다. 리콜라 캔디
맛있는 기분 전환. 립톤 아이스티
입은 즐겁게 몸은 가볍게. 메타그린
한 잔의 커피는 한 번의 여행입니다. 맥심
떡순이들을 위한 다이어트 필수템. 마이비밀 다이어트 떡볶이
당신이 몰랐던 진짜 베이컨을 보여줄게요. 사실주의 베이컨
국내 최초! 마시는 비건 식사. 비건 테이블
당신의 향기를 사랑합니다. 맥심
옛날 그 정성. 그 맛. 남부식품
신선한 우유를 가장 신속하게. 부산우유
상큼한 기분 가벼운 이슬. 자몽에 이슬
카레가 맛있어야 카레가 맛있지요. 오뚜기
아무것도 하지 않으면 아무일도 일어나지 않아. 카스
이천원의 맛집. 롯데리아 착한 메뉴. 롯데리아
커피믹스의 새로운 시작. 네스카페 신선한 모카. 네스카페
뇌의 80%는 수분. 시험 전에 필요한 것은 공부만이 아니다. 포카리스웨트
껍질까지 깨끗합니다. 델몬트 바나나
시대가 달라지면 비타민도 달라져야 합니다. 임팩타민
나를 아끼자. 박카스
경기는 계속되어야 한다. 파워에이드 
봄을 타십니까? 피로를 타십니까? 일동제약 아로나민 골드
바로 마르지 않는 보습 레시피. 라네즈
듀이트리로 퀵모닝하세요. 듀이트리 마스크
바르는 홍삼을 만나다. 동인비
반짝반짝 액체 컬러. 라네즈 리퀴드 루즈
바다의 검은 황금 다시마를 한 병에 담은. 감각적인 고보습 토너. 리브스비
한 장의 시트가 피부를 얼마나 바꿀 수 있을까. 더우주 파워캡슐 마스크
세월의 흔적을 지우는 프리미엄 안티에이징 포스 수프림. 비오템 옴므
심해 속 레드 알개가 끌어올린 피부 탄력 100%. 비오템
단 한 병으로 더 진한 안티에이징. 수려한
하루 한 팩 붙이는 연어 습관. 낫포유
누군가를 위해 예뻐지지 않아. 낫포유
다운패딩보다 1.5배 따뜻한 신슐레이트 리버시블패딩
하나의 가방 열 가지 스타일. 루프세터 픽서 모듈러 백팩
주머니 속의 지갑. 새로운 컨셉의 지갑. 케리
올겨울 우리의 심장은 함께 뛴다. 노스페이스
여행, 액티비티, 일상생활에도 이 옷 하나면 어디든 갈 수 있어! 정욱준
가방 참 멋지네요. 매일 듣게 될 거예요. 스위치
평범함에서 찾은 특별함. 젠더리스 속옷. 케인피오니어
한복부터 캐주얼까지 어울리는 카멜레온 데일리백. 레온백
매일 메고 싶은 메일백. 바이시클트로피
꿈의 백팩에 유기동물을 향한 사랑을 더하다. 클로렌즈
이 세상 비누향 유목민들 모여라! 리베르 섬유향수
제 삶의 영원한 주제는 아름다움입니다. 삼성전자 파브
잠들기 전 불을 끄러 가는 여정을 해결해드립니다. 스위처
대한민국 라이프스타일의 시작과 끝. 하이마트
너를 좋아해라고 말하는 대신 나는 셔터를 눌렀다. 올림푸스
듣지 말고 느껴라! 큐리텔
우리가 키운 건 단지 건조기의 크기가 아니라 깨끗함의 크기. 삼성 그랑데 건조기
직수보다 깨끗한 직수. 쿠쿠셀프 직수 정수기
새로운 바람이 분다. 라스코 타워 써큘레이터
빛을 담은 반려동물 정수기. 두잇 워터팟
돌려끼워 귀에 딱! 무선 이어폰 비핏
세상에 없던 디자인 전구로 인테리어 완성. 루미르
토익은 기술이야. 영단기
당신도 진짜 영어 할 수 있습니다. 야나두
비인간적 점수상승. 산타토익
토익 문제집의 종말. 산타토익
문제집은 필요 없다 산타토익에 다 있다. 산타토익
독서가 취미가 되는 당신을 만나보세요. 밀리의 서재
책 한 권 값으로 2만 권을 자유롭게. 밀리의 서재
당신은 지금까지 영어를 잘못 배웠습니다. 짠내영어
공부해 본 선배들의 강추! 누드교과서
평생 기억되는 영어. 구구스터디
좋은 책 만들기 반세기. 동아출판사 
배움을 즐기는 우리 아이 곁에. 교원
변화를 주도하는 사람들을 위한. 포츈
지구에서 가장 행복한 곳. 디지니랜드
모두의 하늘을 열다. 에어아시아
모두가 아이가 되는 그 계절이 왔다. 한국관광공사
여행을 가볍게. 노랑풍선
여행의 힘을 믿으세요. 에어부산
오늘 아침은 개와 산책. 해 질 녘은 코끼리로 산책. 타이국제항공
지금부터 난. 아무것도 하지 않겠다! 제주신라호텔
누구나 실수하기 마련입니다. 현대해상 하이카
긴 인생 아름답도록. 삼성생명
내 몸이 예전 같지 않을 때 약국에서 만날 수 있습니다. 광동 경옥고
보험 담은 첫 번째 지갑. 보갑
긴 인생 아름답도록. 삼성생명
지금 하고 싶은 것. 지금 하세요. 엘지카드
알아서 챙겨주는 나만의 머니 트레이너. 핀크
행복한 은퇴설계의 시작. 미래에셋
당신보다 당신을 더 잘 아는 현대증권이 있습니다. 현대증권
헤이 카카오. 택시 호출해 줘. 카카오택시
지하철 타는 똑똑한 습관. 카카오지하철
이야기로 천만을 움직이다. 카카오페이지
맛집 광고는 이제 그만! 카톡 친구들의 솔직한 맛집 리뷰. 카카오플레이스
단 하루를 떠나도. 특별하게. 크라이슬러
독일차보다 놀라운 독일차. 아우디
변화와 혁신의 차이를 아신다면 당신은 쏘나타입니다. 쏘나타
그리고 24살. 첫 번째 나의 선택. 코란도
둘이 떠나면 여행. 가족이 떠나면 축제. 새롭게 카니발을 열다. 카니발
한 만큼 돌려받는 머니 백 다이어트. 눔
세상이 나에게 친절해졌다. 본 아이템성형외과
아이의 세계는. 세계보다 크다. 레고
사람을 구하는 건. 사람밖에 없다. 공익광고협의회 
다음날 문 앞에서 새벽 배송. 헬로네이처
꽃 생활의 시작. 꽃 정기구독 서비스 블루미
누구나 15초 만에 스타가 될 수 있다. 틱톡
즐거운 적립 생활의 시작. 캐시슬라이드
뉴스에는 위아래가 없다. 스브스뉴스
우리는 지금 담배와의 전쟁 중입니다. 보건복지부`,
		},
		{
			title: '아무말 대잔치',
			content: `오늘 아침에 일어나서 커피를 한 잔 마셨어
점심 메뉴로 무엇을 먹을까 고민 중이야
날씨가 추워져서 따뜻한 옷을 꺼내 입었어
책상 위에 새로운 액자를 놓으니 분위기가 달라졌어
운동을 끝내고 나니 기분이 상쾌해졌어
주말에는 친구들과 함께 영화를 보러 갈 거야
아침에 일찍 일어나서 산책을 하는 게 좋아
오늘 저녁은 집에서 간단히 샐러드를 만들어 먹을 거야
핸드폰 배터리가 다 되어서 충전기를 찾고 있어
방 청소를 하고 나니 마음도 정리가 되는 것 같아
새로 산 신발이 너무 편해서 자주 신게 될 것 같아
지하철을 타고 출근하는 길에 책을 읽었어
컴퓨터를 켜고 오늘 할 일 목록을 확인했어
창밖을 보니 비가 오는 것 같아 우산을 챙겼어
고양이가 창가에 앉아서 나비를 바라보고 있어
아이스크림을 먹고 싶은데 집에 있는 걸까?
친구가 선물로 준 머그잔을 매일 사용하고 있어
집에 가는 길에 마트에 들러 장을 좀 봐야겠어
음악을 들으며 요리하는 건 정말 즐거운 일이야
침대에 새로운 침구를 깔고 나니 기분 전환도 됐어
커피숍에서 새로운 메뉴를 시도해 보았어
이메일을 확인하고 회신할 것들을 정리했어
집 앞 공원에서 새로운 운동기구를 설치했더라
도서관에 가서 관심 있는 책 몇 권을 빌렸어
신발을 정리하다가 오래된 운동화를 발견했어
스마트폰 앱을 업데이트하니 더 빨라진 것 같아
오랜만에 가족들과 함께 식사를 준비했어
사진을 정리하다가 어릴 적 추억에 잠겼어
요즘 드라마가 재미있어서 매일 챙겨보고 있어
커피 한 잔과 함께 책을 읽는 시간이 좋아
퇴근 후에는 집에서 편안하게 쉬고 싶어
노트북을 켜고 오늘의 할 일을 계획했어
장바구니를 들고 장을 보러 가는 길이야
이불을 깨끗이 빨아서 햇볕에 말렸어
거울을 보니 머리 스타일을 바꿀 때가 된 것 같아
자전거 타기 좋은 날씨네, 한 바퀴 돌아볼까?
새로운 운동화를 신고 달리기를 시작했어
집에 있는 식물에 물을 주는 걸 잊지 않았어
오늘 저녁에는 맛있는 파스타를 만들어 먹을 거야
커튼 색상을 바꿔서 방 분위기를 새롭게 했어
휴대폰 알람 소리에 잠에서 깨어났어
지갑을 챙기고 외출 준비를 마쳤어
편의점에 가서 간식거리를 좀 사 왔어
주말에 친구들과 함께 바베큐 파티를 할 거야
오랜만에 편지를 써서 친구에게 보냈어
옷장 정리를 하면서 안 입는 옷을 기부했어
새로 산 식물을 거실에 놓으니 화사해졌어
저녁 산책을 하며 하루를 마무리했어
집안 대청소를 하고 나니 기분이 좋아졌어
아침 일찍 일어나서 요가를 조금 했어`,
		},
	],
	en: [
		{
			title: 'Proverbs',
			content: `Go to vintage without baskets
Secret of success is constancy to purpose
Danger foreseen is half avoided
Good name is better than a golden girdle
If the wind will not serve, take to the oars
He who carries nothing loses nothing
Non but a wise man can employ leisure well
Honey catches more flies than vinegar
Hog in armour is still but a hog
He that can make a fire well can end a quarrel
Friend to all is a friend to none
Gift long waited for is sold, not given
Deep sorrow has no tongue
No man can call again yesterday
Make yourself all honey, and the flies will devour you
Second thoughts are best
keep the common road, and you are safe
It is good to have friends but bad to need them
Kill one with kindness
Eating little and speaking little can never do harm
Idle men are dead all their life long
A small leak will sink a great ship
He who begins many things, finishes but few
The grass is always greener on the other side of the fence
Respect is greater from a distance
Avarice is the only passion that never ages
From saying to doing is a long step
Talk of the devil, and he is bound to appear
Learn to say before you sing
Covetousness is always filling a bottomless vessel
The first blow is halfly the battle
Beauty is in the eyes of the beholder
Time and tide wait for no man
Hunger is the best sauce
A little learning is a dangerous thing
He that has a great nose thinks everybody is speaking of it
There is truth in wine
The more noble, the more humble
The pleasures of the mighty are the tears of the poor
All that glitters is not gold
Out of the frying pan into the fire
Money will do anything
The tailor makes the man
When the well is full, it will run over
The used key is always bright
Men are blind in their own causes
Two dogs strive for a bone, and a third runs away with it
Nature admits not a lie
Knave is one knave, but a fool is many
Manners are stronger than laws
If you would wish the dog to follow you, feed him
Never judge by appearances
Age is a matter of feeling, not of years
Hungry dogs will eat dirty puddings
Draw not your bow until your arrow is fixed
From nothing, nothing can come
Remove an old tree and it will die
Bacchus kills more than Mars
Time discloses all things
The first step is always the hardest
Necessity is the mother of invention
The pen is mightier than the sword
Clothes do not make the man
Novelty is the great parent of pleasure
You can't seek Lady Luck; Lady Luck seeks you
Popular opinion is the greatest lie in the world
Delay increases desire and sometimes extinguishes them
The pot calls the kettle black
Everything is good for something
Words are the voice of the heart
Whatever you undertake, think of the end
A bargain usually costs you more in the end
He who knows only his side of the case knows little of that
The longest night must end
The best means of destroying an enemy is to make him your friend
The first step is the hardest
A man is twice miserable when he fears his misery before it comes
The sickness of the body may prove the health of the mind
To refuse graciously is half to grant a favor
Gentleness corrects whatever is offensive in our manner
My house burned up, but do died the bedbugs
A man with three daughters can sleep his door open
A day is like three autumns
The best of plans is to run away
Pay condolences when the lord's horse dies but not when the lord dies
Even the evil spirits don't know
Knowing characters is worry and trouble
Where three travel together, one will be my teacher
Hearing times is not like seeing once
Father hands down, son hands down
Give the disease and offer the remedy
The sickness of the body may prove the health of the mind
Discard the head and lop off the tail
Fix the hedge gate after you've been robbed
There is love downward but no love upward
Seldom seen, soon forgotten
Anyone who goes hungry for three days will steal
To be prepared is to have no anxiety
The second word makes the quarrel
Charity begins at home
Love is blind; so is hatred
The angry man opens his mouth and shuts his eyes
The only way to have a friend is to be one
Laugh and the world laugh with you; weep, and you weep alone
The best things in life are free
Better be a bird in the wood than one in the cage
When angry count ten; when very angry, a hundred
If you submit to one wrong you bring on another
He can't see the forest for the trees
What's yours is mine, and what's mine is my own
Sorrow is laughter's daughter
Misfortune seldom comes alone
Misfortune is a good teacher
A desperate disease must have a desperate cure
The remedy is worse than the disease
Like back and expectorate
Quarreling is the weapon of the weak
Like he's bewitched by a goblin
The fowl knows the serpent's sneeze
If you sing before seven, you will cry before eleven
Resolve lasts three days
The day's plan should made out early in the morning
The getting out of the door is the greatest part of the journey
A good beginning makes a good ending
As the life is, so is the end
Without a friend to share them, no goods we posses are rally enjoyable
Charity begins at home
If you're not stingy, you're not wealthy, and if you're not wealthy, you're not stingy
A song will outlive sermons in the memory
The merry in heart have a continual feast
Visits should be short like a Winter's day
There's no joy in anything unless we share it
Not the slightest hint to the liver
Snow is the poor man's fertilizer
It's easier to fall than to get on your feet
The filth under the white snow the sun discovers
Mind unemployed is mind unenjoyed
It's time to wink as well as to see
All that we know is that we know nothing
When you are good to others, you are best to yourself
Pull down on your hat on the wind side
There is an eternal bliss in a momentary kiss
One evil breeds another
All is luck or illluck in this world
Cold hand, warm heart
A clean glove often hides a dirty hand
Truth is the best advocate
Rise with the Sun and enjoy the day
What much is worth comes from the earth
Time flies like an arrow and time lost never returns
Sweet sixteen and never been kissed
Nothing costs so much as what is given to us
Throw the baby out with the bath water
The richer get richer and the poor get babies
The more violent the storm the sooner it is over
A stitch in time saves nine
Two in distress make trouble less
If the shoes fits, wear it
Knowing characters is worry and trouble
If you would be loved, love and be lovable
Over shoes, over boots
That's the way the cookie crumbles
Every cook praises his own soup
Great talent takes time to ripen
After cheese comes nothing
If you have bread, don't look for cake
The biter is sometimes bit
A hot potato is hard to cool
The sweetest grapes hang highest
An eye for an eye and a tooth for an tooth
A woman's tongue is only three inches long, but it can kill a man six feet high
The burden is light on the shoulders of others
Let your nose go in front, but don't always follow it
He who loses money, loses much; he who loses a friend loses more; he who loses his nerve, loses all
Don't stick out your neck for someone to hang a rope around
Wisdom may come out of the mouths of babes
If you have no courage, you must have fast legs
Once a heel, always a heel
What comes from the heart goes to the heart
Two heads are better than one
A beard well lathered, is halfshaved
Don't judge a man until you've walked in his boots
The price of your hat isn't the measure of your brain
The tongue is boneless but it breaks bones
One hand washes the other
Never swap horses crossing a stream
Two men may meet but never two mountains
The greatest rivers must run into the sea
All water runs to the sea
The frog in the well knows nothing of the great ocean
A wicked book is the more wicked because it cannot repent
Reading makes a full man
Too many books make us ignorant
Don't judge a book by its cover
God gives every bird his worm, but he does not throw it into the nest
A good book is your best friend
Without patronage art is like a windmill without wind
The strongest oaths are straw to the fire
Best dealing with an enemy when you take him at his weakest
Freedom is not worth having if it does not include the freedom to make mistakes
Never trouble trouble till trouble troubles you
Blind chance sweeps the world along
Punish yourself, abandon yourself
Good courage breaks bad luck
The pot calls the kettle black
The yellow dog is brother of the wolf
The grass is always greener on the other side
Do not judge a man by the whiteness of his turban
Time is the best counsellor
Soft fire makes sweet malt
Some rise by sin, and some by virtue fall
A quiet conscience sleeps in thunder
Lying and stealing are next door neighbours
Tighten your helmet strings in victory
We are born crying, live complaining, and die disappointed
We cannot come to honour under coverlet
We are the authors of our own disasters
There is no royal road to learning
That's like putting a fifth wheel to a coach
Prefer loss to unjust gain
Presents keep friendship warm
A man must wait for the right moment
Law makers should not be law breakers
In good fortune, prudence; in ill fortune, patience
He that goes to bed thirsty rises healthy
Pride goes before a fall
Never too much of anything
Man proposes but God disposes
An injury forgiven is better than an injury revenged
He that excuses himself accuses himself
Purpose is what gives life a meaning
The net of the sleeper catches fish
Man's best candle is his understanding
Learning without thought is labour lost
In juries we write in marble; kindness in dust
He that falls today may rise tomorrow
I cannot be your friend and your flatterer too
The husband is always last to know
If you would enjoy the fruit, pluck not the flower
If you trust before you try, you may repent before you die
Shoemaker's wives are worst shod
Poor and liberal, rich and covetous
Listen to what is well said even from an enemy
The old forget; the young don't know
Peace is the fairest form of happiness
Luck comes to those who look after it
A man finds himself seven years older than the day after his marriage
The last drop makes the cup run over
Early to bed and early to rise, makes a man healthy, wealthy and wise
Birth is much, but breeding is more
A black plum is as sweet as a white
Do in Rome as the Romans do
Fairest gems lie deepest
Good and evil are chiefly in the imagination
If you have no enemies, it is a sign fortune has forgotten you
The fortune teller cannot tell his our fortune
From short pleasure, long repentance
Gain at the expense of reputation should be called loss
To climb steep hills requires slow pace at first
A bold attempt is half success
Be commonplace and modest, and everything is within your reach
Be swift to hear, slow to speak
Follow the river, and you'll get to the sea
The fortune of the house stands by its virtue
Cleave the log according to the grain
Blessings are not valued till they are gone
Be not the first to quarrel nor the last to make it up
He who buys what he doesn't want will sell what he does want
Life admits not of delays
Love shortens distance
Man can do no more than he can
A man apt to promise is apt to forget
Knowledge has no enemy but ignorance
Iron not used soon rusts
It is always time to do good
No medicine can cure folly
The mind makes heaven of hell and hell of heaven
A man has choice to begin love, but not to end it
Life is made up of little things
It is a long lane that has no turning
None is deceived but he who trusts
Little said soon amended
Live not to eat, but eat to live
Life is half spent before we know what it is`,
		},
		{
			title: 'Quotes About Love',
			content: `Love is composed of a single soul inhabiting two bodies
To love and be loved is to feel the sun from both sides
The best thing to hold onto in life is each other
Love is when the other person's happiness is more important than your own
At the touch of love everyone becomes a poet
Where there is love there is life
Love is not just looking at each other, it's looking in the same direction
Love is the beauty of the soul
There is only one happiness in this life, to love and be loved
Love is an irresistible desire to be irresistibly desired
We love because it's the only true adventure
Love is a better master than duty
Love doesn't make the world go round Love is what makes the ride worthwhile
The art of love is largely the art of persistence
Love is all we have, the only way that each can help the other
Love is the only force capable of transforming an enemy into a friend
Love is a canvas furnished by nature and embroidered by imagination
A simple 'I love you' means more than money
Love is not about possession, it's all about appreciation
Love is a game that two can play and both win
Grow old with me! The best is yet to be
To be brave is to love unconditionally without expecting anything in return
Love is like the wind, you can't see it but you can feel it
True love is eternal, infinite, and always like itself
Love is a choice you make from moment to moment
The heart has its reasons which reason knows not
We are most alive when we're in love
A loving heart is the truest wisdom
Life is the flower for which love is the honey
Love cures people—both the ones who give it and the ones who receive it
Love is when you meet someone who tells you something new about yourself
Love is the poetry of the senses
Love is something eternal; the aspect may change, but not the essence
To love at all is to be vulnerable
Love is the only gold
The giving of love is an education in itself
Love is what you've been through with somebody`,
		},
		{
			title: 'Everyday conversations',
			content: `I'm on my way to the grocery store
I've been thinking about you all day
Let's go for a walk in the park
I need to buy some new shoes soon
I can't find my keys anywhere!
That new café downtown is amazing
Let's plan a trip for the weekend
I just finished reading a great book
My phone battery is almost dead
I'm trying to learn a new language
That joke you told was so funny!
I think I need a haircut
I forgot to water the plants
That new restaurant has great reviews
Let's take a selfie together!
I'm feeling a little under the weather
We should invite friends over for dinner
This song always makes me think of you
I have a dentist appointment tomorrow
Let's make some homemade pizza
I'm really proud of you
Let's go out for ice cream
I'm excited for the upcoming holiday
I lost track of time while reading
Let's go see a live show
I need to do laundry this weekend
That was a great workout!
Let's bake cookies this evening
Let's go for a bike ride
I'm looking for a new hobby
We should start a garden
I'll meet you at the cafe
This puzzle is quite challenging`,
		},
	],
};

export default data;
