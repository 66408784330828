<script>
	import langs from './langs.js';
	import { settings } from './settings.js';

	export let key = '';
	export let ko = '';
	export let en = '';
	export let append = '';

	let disp = '';
	$: {
		if (key !== '') {
			disp = langs[key][$settings.language];
		} else {
			disp = $settings.language === 'en' ? en : ko;
		}
	}
</script>

<span class="trans">
	{disp}
	{append}
</span>
