<script>
	import _ from 'lodash';
	import {settings} from './settings.js';
	import langs from './langs.js';
	import Typing from './Typing.svelte';
	import data from './short.js';

	const MAX_SENTENCE = 5;
	
	export let params;
	let startIndex = 0;

	let prevSpeed = 0;
	const targetIndex = params[0];
	const row = data[$settings.language].find((row, index) => index == targetIndex);
	const rows = row.content.split('\n');
	let sentence = _.sample(rows).trim();

	function goNextSentence(correctRate, speed) {
		sentence = '';
		prevSpeed = speed;
		setTimeout(() => {
			sentence = _.sample(rows).trim();
		}, 10);
	}
</script>

<style lang="scss">
</style>

<div class="frame-inner wide">
	<div class="frame-header">
		<div class="btn-closed"><span>&times;</span></div>
		{row.title}
		<div></div>
	</div>
	<div class="frame-content">
		{#if sentence !== ''}
			<Typing type="short" data={sentence} startIndex={startIndex} maxSentence={MAX_SENTENCE} onFinish={goNextSentence} prevSpeed={prevSpeed} />
		{/if}
	</div>
</div>
